import React, { useState } from "react";
import setAxiosHeaders from "../lib/AxiosHeaders";
import axios from "axios";
import {useQuery} from "react-query";
import { Button, Dropdown } from 'react-bootstrap'

// Stylesheet
//import './SimpleProductPicker.scss'

const fetchCategories = () => {
    setAxiosHeaders();
    return axios.get('/api/v1/categories');
}

const fetchProductsByCategoryId = (categoryId) => {
    setAxiosHeaders();
    return axios.get(`/api/v1/categories/${categoryId}/`);
}

export const SimpleProductPicker = (props) => {
    // TODO: Get this from props
    //const [selectedCategory, setSelectedCategory] = React.useState(props.categoryId);
    const [selectedCategory, setSelectedCategory] = React.useState(props.categoryId);

    const {
        isLoading: isLoadingCategories,
        data: categories,
        isError: isErrorCategories,
        error: errorCategories} =
        useQuery('category-list', fetchCategories);

    const {
        isLoading: isLoadingProducts,
        data: product_types,
        isError: isErrorProductTypes,
        error: errorProductTypes} =
        useQuery(['productType-list', selectedCategory], () => fetchProductsByCategoryId(selectedCategory), {
            enabled: !!selectedCategory
        });

    if (isLoadingCategories || isLoadingProducts) {
        return <div>Loading...</div>;
    }

    if (isErrorCategories) {
        return <div>Error: {errorCategories.message}</div>;
    }

    const menuHeaderElement = $('#product_type_listing .list-group.list-group-horizontal')
    const onProductScroll = (scrollValue, speed) => {
        menuHeaderElement.animate(scrollValue, speed);
    }
    const colorPick = (e) => {
        props.setSelectedColor(e.target.dataset.color);

    }

    return (
        <div id={'product_type_listing'}>
            <div className="btn-group overflow-auto tab-header" role="group" aria-label="Basic radio toggle button group">
                {categories?.data.map((category, index) => {
                    return (
                        <div
                            className="col"
                            key={`div` + index}
                        >
                            <input
                                key={`input` + index}
                                data-category-id={category.id}
                                data-selected-category={selectedCategory}
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id="btnradio1"
                                autoComplete="off"
                                onChange={() => setSelectedCategory(category.id)}
                                checked={selectedCategory == category.id ? "checked" : undefined}
                            ></input>
                            <label
                                key={`label` + index}
                                className={`btn ${selectedCategory == category.id ? `btn-secondary` : ''}`}
                                style={{borderRadius: 0, backgroundColor: selectedCategory == category.id && 'rgb(242, 242, 242)'}}
                                onClick={() => {
                                    setSelectedCategory(category.id);
                                }}>
                                {category.title}
                            </label>
                        </div>
                    )
                })}
            </div>
            <div className="tab-body-wrapper">
                <div className={`list-group ${!props.isMobileView ? 'list-group-horizontal overflow-auto' : ''} tab-body-wrapper__list`}>
                    {!props.isMobileView && !!props.availableColors.length && <div className="product-color">
                        <p>Product color</p>
                        <Dropdown>
                            <Dropdown.Toggle className="product-color__toggle" style={{backgroundColor: props.availableColors[0].hex}}>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="product-color__menu-items">
                                {props.availableColors.map((val, index) => (<Button key={`color-item-${index}`} title={val.name} style={{backgroundColor: val.hex}} data-color={val.name} className="product-color__menu-items__item" onClick={colorPick} ></Button>))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>}
                    {!!product_types?.data.length && product_types?.data.length > 6 && <div
                        onClick={() => onProductScroll({scrollLeft: 0}, 800)}
                        className="btn-scroll btn-scroll--left"
                    >{'<'}</div>}
                    {product_types?.data.map((productType, index) => {
                        return (
                            <ProductTypeItem
                                productType={productType}
                                key={`product-type-item-${index}`}
                                keyValue={`productTypes` + index}
                                setProductTypeId={props.setProductTypeId}
                                setExternalProductId={props.setExternalProductId}
                                onClose={props.onClose}
                            />
                        )
                    })}
                    {!!product_types?.data.length && product_types?.data.length > 6 &&  <div
                        onClick={() => onProductScroll({scrollLeft: '+=1500'}, 1500)}
                        className="btn-scroll btn-scroll--right"
                    >{'>'}</div>}
                </div>
            </div>
        </div>
    )
};

export const ProductTypeItem = props => {
    const { keyValue, productType, setProductTypeId, setExternalProductId } = props
    const [clicked, setClicked] = useState('');

    const onProductType = () => {
        const cards = document.querySelectorAll('#product_type_listing .list-group-item .card');

        cards.forEach(card => {
            card.classList.remove('border');
        });

        clicked ? setClicked('') : setClicked('active');

        setProductTypeId(productType.id)
        setExternalProductId(productType.external_id)
        props.onClose();
    };

    return (
        <div
            key={keyValue}
            className={'list-group-item col-6 col-md-3 col-lg-2 product-type-item'}
        >
            <div
                className={`card ${clicked ? `border` : ''}`}
                onClick={onProductType}
            >
                <img
                    src={productType.image_url}
                    alt={productType.title}
                    width={'100px'}
                    height={'100px'}
                    className={'rounded mx-auto d-block'}
                />
                <div className={'card-body other_product'}>
                    <p className={'card-text other_product_title'}>{productType.title}</p>
                </div>
            </div>
        </div>
    )
}