import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { SimplePlacementPicker } from "../SimplePlacementPicker/SimplePlacementPicker";
import { CreateNewProduct } from "../CreateNewProduct/CreateNewProduct";

export const MobileHeader = ({
                                 template,
                                 templates,
                                 setTemplate,
                                 setTemplates,
                                 placementRef,
                                 fitToScreen,
                                 drawingHistory,
                                 title,
                                 currentUser,
                                 productTypeId,
                                 selectedProductVariantId,
                                 elems,
                                 previewStageRef,
                                 printFiles,
                                 externalProductId,
                                 externalVariantId
                             }) => {

    const onUndo = () => {
        console.log("Undo");
    }

    const onRedo = () => {
        console.log("Redo");
    }

    const onSettings = () => {
        console.log("Settings");
    }

    const onSave = () => {
        console.log("Save");
    }

    const handleDropdownToggle = (isOpen) => {
        if (isOpen) {
            console.log("Placement Picker is now visible");
        }
    }

    return (
        <div className="mobile-header d-lg-none d-flex justify-content-between align-items-center px-3 py-2 bg-dark text-white">

            {/* Placement Picker Dropdown */}
            <Dropdown onToggle={handleDropdownToggle}>
                <Dropdown.Toggle variant="secondary">
                    Placement
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <div className="col-lg-1 content__sides">
                            <div className="sides">
                                <SimplePlacementPicker
                                    template={template}
                                    templates={templates}
                                    setTemplate={setTemplate}
                                    setTemplates={setTemplates}
                                    placementRef={placementRef}
                                    fitToScreen={fitToScreen}
                                />
                            </div>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {/* Save Button */}
            <div id="btn-create-new-product">
                <CreateNewProduct
                    title={title}
                    currentUser={currentUser}
                    productTypeId={productTypeId}
                    selectedProductVariantId={selectedProductVariantId}
                    elems={elems}
                    templates={templates}
                    previewStageRef={previewStageRef}
                    printFiles={printFiles}
                    externalProductId={externalProductId}
                    externalVariantId={externalVariantId}
                />
            </div>
        </div>
    );
}
