
import React from "react";
import rotateIcon from "../../../../assets/images/icon/rotate.svg";
import {Button, Dropdown} from "react-bootstrap";
import rotateLeftIcon from "../../../../assets/images/icon/rotate-left.svg";
import rotateRightIcon from "../../../../assets/images/icon/rotate-right.svg";

export const RotateControls = ({
                                   isMobileView,
                                   onButtonClick,
                                   mobileIconStyles,
                                   props
                               }) => {

    if (isMobileView) {
        return (
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={onButtonClick}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Rotate22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${rotateIcon})`
                    }}
                />
                <div>Rotate</div>
            </button>
        )
    }
    else {
        return (
            <Dropdown>
                <Dropdown.Toggle>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div
                            className={isMobileView ? "mobile_context_button_icon" : ""}
                            data-icon="Rotate"
                            style={{
                                ...mobileIconStyles.desktopIcon,
                                backgroundImage: `url(${rotateIcon})`,
                            }}
                        />
                        Rotate
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Button onClick={() => handleRotateLeft(
                        props.elems,
                        props.setElems,
                        props.selectedId,
                        props.selectedItem,
                        props.selectedElement,
                        props.onElementsChange)}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="RotateLeft"
                                style={{
                                    ...mobileIconStyles.icon,
                                    width: '20px',
                                    height: '20px',
                                    backgroundImage: `url(${rotateLeftIcon})`,
                                    marginRight: '5px'
                                }}
                            />
                            Rotate Left
                        </div>
                    </Button>
                    <Button onClick={() => handleRotateRight(
                        props.elems,
                        props.setElems,
                        props.selectedId,
                        props.selectedItem,
                        props.selectedElement,
                        props.onElementsChange)}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="RotateRight"
                                style={{
                                    ...mobileIconStyles.icon,
                                    width: '20px',
                                    height: '20px',
                                    backgroundImage: `url(${rotateRightIcon})`,
                                    marginRight: '5px'
                                }}
                            />
                            Rotate Right
                        </div>
                    </Button>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}


export const RotateControlsContent = ({
                                          elems,
                                          setElems,
                                          selectedId,
                                          selectedItem,
                                          onElementsChange,
                                          selectedElement,
                                          onRotateLeft,
                                          onRotateRight,
                                          mobileIconStyles,
                                          isMobileView
                                      }) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={() => onRotateLeft(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange)}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="RotateLeft"
                    style={{
                        ...mobileIconStyles.icon,
                        // You might want to provide a specific icon for scaling up
                        backgroundImage: `url(${rotateLeftIcon})`
                    }}
                />
                <div>Rotate Left</div>
            </button>
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={() => onRotateRight(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange)}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="RotateRight"
                    style={{
                        ...mobileIconStyles.icon,
                        // And for scaling down
                        backgroundImage: `url(${rotateRightIcon})`
                    }}
                />
                <div>Rotate Right</div>
            </button>
        </div>
    )};

export const handleRotateRight = (
    elems,
    setElems,
    selectedId,
    selectedItem,
    selectedElement,
    onElementsChange
) => {
    // Increase the rotation angle by 15 degrees
    let newRotation = selectedItem.rotation + 15;

    const element = {
        ...selectedItem,
        rotation: newRotation
    };

    selectedElement.rotation(newRotation);

    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    onElementsChange(elems_copy.slice());
}

export const handleRotateLeft = (
    elems,
    setElems,
    selectedId,
    selectedItem,
    selectedElement,
    onElementsChange
) => {
    // Decrease the rotation angle by 15 degrees
    let newRotation = selectedItem.rotation - 15;

    const element = {
        ...selectedItem,
        rotation: newRotation
    };

    selectedElement.rotation(newRotation);

    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    onElementsChange(elems_copy.slice());
}


const onRotationChange = (e) => {

    let rotationAngle = Number(e.target.value)
    const element = {selectedItem, rotation: rotationAngle};
    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    setRotation(e.target.value);
    onElementsChange(elems_copy.slice());
}

const rotate = () => {
    let rotationAngle = Number(e.target.value)
    // rotation from https://stackoverflow.com/a/2323034/155
    let newRotation = selectedItem.rotation + rotationAngle;
    newRotation = newRotation % 360;
    newRotation = (newRotation + 360) % 360;
    if (newRotation > 180) newRotation -= 360;

    const element = {selectedItem, rotation: newRotation};
    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    setRotation(newRotation);
    onElementsChange(elems_copy.slice());
}