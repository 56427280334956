import React, {useState, useEffect, useRef} from 'react';
import {Path} from '../TextWarp/Path';
import {calculateViewportCenter} from "../lib/utils";
import Session from 'svg-text-to-path';  // For Text to SVG Path
import {ChromePicker} from 'react-color'; // Import the color picker component
import {Swatch} from '../SimpleElementOptions/SwatchControls'; // Import the Swatch component
//import { buildColorMap, updateSVGColors, extractColorsFromSVG } from "../SimpleElementOptions/propertiesUtils'; // Import color map functions
import CustomFontSelector from '../SimpleElementOptions/CustomFontSelector'; // Adjust the import path as needed
import {useSelectionContext} from "../../contexts/SelectionContext";


//import {loadElement} from "../lib/assetUtils";

const googleFonts = [
    'Roboto', 'Open Sans', 'Lato', 'Oswald', 'Montserrat', 'Raleway', 'PT Sans', 'Ubuntu', 'Rubik Burned',
    // Add more fonts as desired
];

const fontSizes = [
    '64', '72', '96', '128', '144', '192',
];

const TextAsset = ({
                       loadElement,
                       isListVisible,
                       isAuthenticated,
                       selectedButton,
                       setElems,
                       elems,
                       placementRef,
                       stageRef,
                       onClose = () => {
                       },
                       printAreaRef,
                       isMobileView,
                   }) => {


    const {
        activeNodesRef,
        selectedId,
        selectedElement,
        selectElement,
        deselectElement,
    } = useSelectionContext();


    const [distortionType, setDistortionType] = useState("");
    const [bendValue, setBendValue] = useState(30);
    const [horizontalDistort, setHorizontalDistort] = useState(0);
    const [verticalDistort, setVerticalDistort] = useState(0);
    const [selectedFont, setSelectedFont] = useState(googleFonts[0]);
    const [selectedSize, setSelectedSize] = useState(144);

    const [displayFillColorPicker, setDisplayFillColorPicker] = useState(false);
    const [displayStrokeColorPicker, setDisplayStrokeColorPicker] = useState(false);

    const [selectedCssClass, setSelectedCssClass] = useState(null);
    const [fillColor, setFillColor] = useState('#000000'); // Default color
    const [strokeColor, setStrokeColor] = useState('#000000'); // Default color
    const [strokeWidth, setStrokeWidth] = useState(0); // Default stroke width

    const [textValue, setTextValue] = useState('');
    const [svgDataUrl, setSvgDataUrl] = useState(""); // State to store the SVG data URL
    const svgContainerRef = useRef(null);

    //const isTextSelected = selectedElement && selectedElement.attrs.type === 'path';
    const [isTextSelected, setIsTextSelected] = useState(false);

    // Update text value when a text element is selected
    useEffect(() => {
        // Update component state based on selected element, if applicable
        if (selectedElement) {
            const attrs = selectedElement.attrs;
            if (attrs.type === 'path') {
                setIsTextSelected(true);
                setTextValue(attrs.textValue); // Assuming textValue is stored in attrs
                setSelectedFont(attrs.fontFamily);
                setSelectedSize(String(attrs.fontSize));
                setDistortionType(attrs.distortionType);
                setBendValue(attrs.bendValue);
                setHorizontalDistort(attrs.horizontalDistort);
                setVerticalDistort(attrs.verticalDistort);
                setFillColor(attrs.fill);
            } else {
                setIsTextSelected(false);
                setTextValue(''); // Clear text value
                setDistortionType("");
                setBendValue(30);
                setHorizontalDistort(0);
                setVerticalDistort(0);
                setFillColor(null);
            }
        } else {
            setIsTextSelected(false);
            setTextValue(''); // Clear text value
            setDistortionType("");
            setBendValue(30);
            setHorizontalDistort(0);
            setVerticalDistort(0);
            setFillColor(null);
        }
    }, [selectedElement]);

    useEffect(() => {
        if (isTextSelected) {
            handleTextConversion(); // Call a function that reapplies distortion based on current settings
        }
    }, [distortionType, bendValue, horizontalDistort, verticalDistort]);

    useEffect(() => {
        if (isTextSelected) {
            updateOrCreatePath(textValue, selectedFont, selectedSize, strokeWidth, strokeColor);
        }
    }, [textValue, selectedFont, selectedSize, strokeWidth, strokeColor]);

    const updateOrCreatePath = async (textValue, selectedFont, selectedSize, strokeWidth, strokeColor) => {
        // Ensure SVG container ref is used correctly
        if (!svgContainerRef.current) {
            console.error('SVG container ref not found');
            return;
        }

        const {width, height} = measureSvgText(textValue, selectedFont, selectedSize);


        const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgElement.setAttribute("width", `${width}px`);
        svgElement.setAttribute("height", `${height}px`);

        const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
        textElement.setAttribute("fill", "black");
        textElement.setAttribute("x", "0");
        textElement.setAttribute("y", selectedSize);
        textElement.setAttribute("font-family", selectedFont);
        textElement.setAttribute("font-size", selectedSize);
        textElement.textContent = textValue;
        svgElement.appendChild(textElement);

        svgContainerRef.current.appendChild(svgElement); // Append SVG to container ref

        // Replace text with path using svg-text-to-path
        const session = new Session(svgElement, {
            googleApiKey: 'AIzaSyCOE5K7L-6bbNK6aY3SBU-ZT8kLVw_D2XU',
        });

        try {
            await session.replaceAll();
            // Assuming the path conversion is successful, and paths have been created
            const paths = svgElement.querySelectorAll('path');

            if (paths.length > 0) {
                // Assuming we take the first path for simplicity
                const pathData = paths[0].getAttribute('d');
                let p = new Path(pathData);

                p.warp({
                    type: distortionType,
                    bend: (bendValue / 100) || 0,
                    distortV: (horizontalDistort / 100) || 0,
                    distortH: (verticalDistort / 100) || 0,
                });

                // Now, let's create a new element for the canvas
                const {centerX, centerY} = calculateViewportCenter(printAreaRef, stageRef, width, height);

                if (isTextSelected && selectedId) {
                    const updatedElems = elems.map(elem => elem.id === selectedId ? {
                        ...elem,
                        data: p.output(),
                        originalData: pathData,
                        fontFamily: selectedFont,
                        fontSize: selectedSize,
                        textValue: textValue,
                        fill: fillColor,
                        stroke: strokeColor,
                        strokeWidth: strokeWidth,
                        distortionType: distortionType,
                        bendValue: bendValue,
                        horizontalDistort: horizontalDistort,
                        verticalDistort: verticalDistort,
                    } : elem);
                    setElems(updatedElems);
                } else {
                    const newPathElement = {
                        id: `path_${Date.now()}`,
                        type: 'path', // Ensure this matches the key in COMPONENTS_MAP
                        data: pathData, // The 'd' attribute of the SVG path
                        originalData: pathData, // Store the original path data for future reference
                        x: centerX,
                        y: centerY,
                        width: '500',
                        height: '200',
                        placement: placementRef.current,
                        textValue: textValue,
                        fontFamily: selectedFont,
                        fontSize: selectedSize,
                        fill: fillColor,
                        stroke: strokeColor,
                        strokeWidth: strokeWidth,
                        distortionType: distortionType,
                        bendValue: bendValue,
                        horizontalDistort: horizontalDistort,
                        verticalDistort: verticalDistort,

                        // Include other necessary properties for your PathShape
                    };

                    setElems([...elems, newPathElement]); // Add the new path element to elems
                    selectElement(newPathElement.id);
                    //setSelectedId(newPathElement.id); // Select the new element if required

                    /*setTimeout(() => {
                        // Use the stageRef to find the node by ID
                        const konvaNode = stageRef.current.findOne(`#${newPathElement.id}`);
                        // Once you have the reference to the rendered Konva node, you can set it.
                        //setSelectedElement(konvaNode);
                        selectElement(newPathElement.id, konvaNode);
                    }, 0); // Timeout of 0 to push to the end of the event loop, after render.
*/
                    // Also set the selected element ref
                    //setSelectedElement(newPathElement);
                }
            }

        } catch (error) {
            console.error('Error converting text to path:', error);
        } finally {
            svgContainerRef.current.removeChild(svgElement); // Remove the SVG element from the container
        }
    }

    const handleTextConversion = async () => {
        if (!isTextSelected || !selectedElement) return;

        const originalPathData = selectedElement.attrs.originalData;
        let p = new Path(originalPathData);

        //Apply distortion
        p.warp({
            type: distortionType,
            bend: (bendValue / 100) || 0,
            distortV: (horizontalDistort / 100) || 0,
            distortH: (verticalDistort / 100) || 0,
        });

        setHorizontalDistort(horizontalDistort);
        setVerticalDistort(verticalDistort);
        setBendValue(bendValue);
        setDistortionType(distortionType);

        updateCanvasWithPath(p.output());
    };

    // Handle clicking on a color swatch
    function handleFillColorClick(cssClass) {
        setSelectedCssClass(cssClass);
        setDisplayFillColorPicker(true); // Show the ChromePicker
        setFillColor(cssClass); // Set the initial color of the ChromePicker to the swatch color
    };

    // Function to handle fill color change
    const handleFillColorChange = (color) => {
        // Update the fill color of the selected path
        const updatedElems = elems.map((elem) => {
            if (elem.id === selectedId) {
                return {...elem, fill: color.hex};
            }
            return elem;
        });
        setElems(updatedElems);
        setFillColor(color.hex);
    };

    const handleStrokeColorChange = (color) => {
        // Update the stroke color of the selected path
        const updatedElems = elems.map((elem) => {
            if (elem.id === selectedId) {
                return {...elem, stroke: color.hex};
            }
            return elem;
        });
        setElems(updatedElems);
        setStrokeColor(color.hex);
    }

    const updateCanvasWithPath = (pathData) => {
        if (isTextSelected) {
            // Update existing path element on the canvas
            const updatedElems = elems.map(elem => {
                if (elem.id === selectedId) {
                    return {
                        ...elem,
                        data: pathData,
                        textValue: textValue,
                        fontFamily: selectedFont,
                        fontSize: selectedSize,
                        distortionType: distortionType,
                        bendValue: bendValue,
                        horizontalDistort: horizontalDistort,
                        verticalDistort: verticalDistort,
                    };
                    // Adjust properties as necessary
                }
                return elem;
            });
            setElems(updatedElems);
        } else {
            // Add new path element to the canvas
            const newElem = {
                id: `path${Date.now()}`,
                type: 'path',
                data: pathData,
                // Set other properties as needed
            };
            setElems([...elems, newElem]);
        }
    };

    const handleAddText = () => {
        updateOrCreatePath(textValue, selectedFont, selectedSize);

        if (isTextSelected.current) {
            // Update existing text element
        }
    }

    const measureSvgText = (text, font, fontSize) => {
        // Create a temporary SVG element
        const svgNS = "http://www.w3.org/2000/svg";
        const tempSvg = document.createElementNS(svgNS, "svg");
        const textElement = document.createElementNS(svgNS, "text");

        // Apply text, font, and size
        textElement.setAttribute("fill", "black");
        textElement.setAttribute("font-family", font);
        textElement.setAttribute("font-size", fontSize);
        textElement.textContent = text;

        // Append text element to SVG
        tempSvg.appendChild(textElement);
        document.body.appendChild(tempSvg); // Temporarily add to body to measure

        // Measure text
        const bbox = textElement.getBBox();
        const width = bbox.width;
        const height = bbox.height;

        // Clean up by removing the temporary SVG
        document.body.removeChild(tempSvg);

        return {width, height};
    };

    const handleFontChange = (fontName) => {
        setSelectedFont(fontName);
        // Additional logic for when a font is selected
    };

    return (
        <div className="p-3 bg-light rounded">
            <div ref={svgContainerRef} style={{display: 'none'}}></div>
            {isTextSelected ? (
                // Form for when a text element is selected
                <>
                    <div className="mb-3">
                        <label htmlFor="textValue" className="form-label">Text:</label>
                        <input
                            id={"textValue"}
                            type="text"
                            className="form-control"
                            placeholder="Enter text..."
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                        />
                    </div>



                    <div className="row mb-3">
                        {/* Font selection */}
                        <div className="col-8">
                            <label htmlFor="fontSelection" className="form-label">Font:</label>
                            <CustomFontSelector
                                selectedFont={selectedFont}
                                onFontChange={handleFontChange}
                            />
                        </div>

                        {/* Font size selection */}
                        <div className="col-4">
                            <label htmlFor="fontSizeSelection" className="form-label">Font Size:</label>
                            <select id="fontSizeSelection" className="form-select" value={selectedSize}
                                    onChange={(e) => setSelectedSize(e.target.value)}>
                                {fontSizes.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="arc_type" className="form-label">Text Effect Type:</label>
                        <select id="arc_type" className="form-select" value={distortionType}
                                onChange={(e) => setDistortionType(e.target.value)}>
                            <option value="">Select</option>
                            <option value="WARP_ARC">Arc</option>
                            <option value="WARP_ARC_LOWER">Arc Lower</option>
                            <option value="WARP_ARC_UPPER">Arc Upper</option>
                            <option value="WARP_ARCH">Arch</option>
                            <option value="WARP_BULGE">Bulge</option>
                            <option value="WARP_FLAG">Flag</option>
                            <option value="WARP_FISH">Fish</option>
                            <option value="WARP_RISE">Rise</option>
                            <option value="WARP_INFLATE">Inflate</option>
                            <option value="WARP_SQUEEZE">Squeeze</option>
                            <option value="WARP_WAVE_LOWER">Wave Lower</option>
                            <option value="WARP_WAVE_UPPER">Wave Upper</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="bendValue">Effect Strength:</label>
                        <input id="bendValue" className="form-range" type="range" min="-100" max="100" value={bendValue}
                               onChange={(e) => setBendValue(e.target.value)}
                               disabled={!distortionType}
                        />

                        <label className="form-label" htmlFor="horizontalDistort">Horizontal Distortion:</label>
                        <input id="horizontalDistort" className="form-range" type="range" min="-100" max="100"
                               value={horizontalDistort}
                               onChange={(e) => setHorizontalDistort(e.target.value)}/>

                        <label className="form-label" htmlFor="verticalDistort">Vertical Distortion:</label>
                        <input id="verticalDistort" className="form-range" type="range" min="-100" max="100"
                               value={verticalDistort}
                               onChange={(e) => setVerticalDistort(e.target.value)}/>


                        <label className="form-label">Border Width:</label>
                        <input
                            className="form-range"
                            type="range"
                            min="0" max="20"
                            value={strokeWidth}
                            onChange={(e) => setStrokeWidth(Number(e.target.value))}
                        />

                    </div>

                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">Fill Color:</label>
                            <div style={{position: 'relative'}}>
                                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    <Swatch
                                        cssClass={fillColor} // Adjust based on Swatch component expectations
                                        isSelected={selectedCssClass === fillColor}
                                        fillValue={fillColor}
                                        elems={elems}
                                        selectedId={selectedId}
                                        selectedElement={selectedElement}
                                        isMobileView={isMobileView}
                                        displayColorPicker={displayFillColorPicker}
                                        setDisplayColorPicker={setDisplayFillColorPicker}
                                        fillColor={fillColor}
                                        setFillColor={setFillColor}
                                        handleFillColorClick={() => handleFillColorClick(fillColor)}
                                    />
                                </div>
                                {/* Color picker that shows up when the swatch is clicked */}
                                {displayFillColorPicker && (
                                    <div>
                                        <div style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px'
                                        }} onClick={() => setDisplayFillColorPicker(false)}/>
                                        <ChromePicker
                                            color={fillColor}
                                            onChangeComplete={handleFillColorChange}
                                            disableAlpha={true}
                                            style={{position: 'absolute', zIndex: '2'}}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className={"col-6"}>
                            <div className="mb-3 position-relative">
                                <label className="form-label">Border Color:</label>
                                <Swatch
                                    cssClass={strokeColor}
                                    isSelected={true}
                                    fillValue={strokeColor}
                                    handleFillColorClick={() => setDisplayStrokeColorPicker(true)} // Assuming this toggles the color picker for stroke color
                                />

                                {displayStrokeColorPicker && (
                                    <div className="position-absolute" style={{top: '100%', zIndex: 2, left: '-120px'}}>
                                        <div style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px'
                                        }} onClick={() => setDisplayStrokeColorPicker(false)}/>
                                        <ChromePicker
                                            color={strokeColor}
                                            onChangeComplete={handleStrokeColorChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Color Picker */}


                </>
            ) : (
                // Form for when no text element is selected
                <>

                    <div className="mb-3">
                        <label htmlFor="textValue" className="form-label">Text:</label>
                        <input
                            id={"textValue"}
                            type="text"
                            className="form-control"
                            placeholder="Enter text..."
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                        />
                    </div>

                    <div className="row mb-3">
                        {/* Font selection */}
                        <div className="col-8">
                            <label htmlFor="fontSelection" className="form-label">Font:</label>
                            <CustomFontSelector
                                selectedFont={selectedFont}
                                onFontChange={handleFontChange}
                            />
                        </div>

                        {/* Font size selection */}
                        <div className="col-4">
                            <label htmlFor="fontSizeSelection" className="form-label">Font Size:</label>
                            <select id="fontSizeSelection" className="form-select" value={selectedSize}
                                    onChange={(e) => setSelectedSize(e.target.value)}>
                                {fontSizes.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <button onClick={handleAddText} className="btn btn-primary">Add</button>
                </>
            )}
        </div>
    );
}

export default TextAsset;
