import {Button, Dropdown} from "react-bootstrap";
import React from "react";
import flipIcon from '../../../../assets/images/icon/flip.svg';
import flipHorizontalIcon from "../../../../assets/images/icon/flip-horizontal.svg";
import flipVerticalIcon from "../../../../assets/images/icon/flip-vertical.svg";



export const FlipControls = ({
                                 isMobileView,
                                 onButtonClick,
                                 mobileIconStyles,
                                 props
                             }) => {


    if (isMobileView) {
        return (
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={onButtonClick}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Flip22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${flipIcon})`
                    }}
                />
                <div>Flip</div>
            </button>
        )
    } else {
        return (
            <Dropdown>
                <Dropdown.Toggle>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div
                            className={isMobileView ? "mobile_context_button_icon" : ""}
                            data-icon="Flip22"
                            style={{
                                ...mobileIconStyles.desktopIcon,
                                backgroundImage: `url(${flipIcon})`,
                            }}
                        />
                        Flip
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Button onClick={() => handleHorizontalFlip(
                        props.elems,
                        props.setElems,
                        props.selectedId,
                        props.selectedItem,
                        props.selectedElement,
                        props.onElementsChange
                    )}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="FlipHorizontal"
                                style={{
                                    ...mobileIconStyles.icon,
                                    width: '20px',
                                    height: '20px',
                                    backgroundImage: `url(${flipHorizontalIcon})`,
                                    marginRight: '5px'
                                }}
                            />
                            {' '} Flip Horizontally
                        </div>
                    </Button>
                    <Button onClick={() => handleVerticalFlip(
                        props.elems,
                        props.setElems,
                        props.selectedId,
                        props.selectedItem,
                        props.selectedElement,
                        props.onElementsChange
                    )}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="FlipVertical"
                                style={{
                                    ...mobileIconStyles.icon,
                                    width: '20px',
                                    height: '20px',
                                    backgroundImage: `url(${flipVerticalIcon})`,
                                    marginRight: '5px'
                                }}
                            />
                            {' '} Flip Vertically
                        </div>
                    </Button>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}


export const FlipControlsContent = ({
                                        elems,
                                        setElems,
                                        selectedId,
                                        selectedItem,
                                        onElementsChange,
                                        selectedElement,
                                        onFlipVertical,
                                        onFlipHorizontal,
                                        mobileIconStyles,
                                        isMobileView
                                    }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={() => onFlipVertical(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange)}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="FlipVertical"
                    style={{
                        ...mobileIconStyles.icon,
                        // You might want to provide a specific icon for scaling up
                        backgroundImage: `url(${flipVerticalIcon})`
                    }}
                />
                <div>Vertical Flip</div>
            </button>
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={() => onFlipHorizontal(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange)}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="FlipHorizontal"
                    style={{
                        ...mobileIconStyles.icon,
                        // And for scaling down
                        backgroundImage: `url(${flipHorizontalIcon})`
                    }}
                />
                <div>Horizontal Flip</div>
            </button>
        </div>
    )};


export const handleHorizontalFlip = (
    elems,
    setElems,
    selectedId,
    selectedItem,
    selectedElement,
    onElementsChange
) => {
    // Flip the horizontal scale
    let newScaleX = -selectedElement.scaleX();
    let newScaleY = selectedElement.scaleY();

    const element = {
        ...selectedItem,
        scaleX: newScaleX,
        scaleY: newScaleY,
    };

    selectedElement.scaleX(newScaleX);
    selectedElement.scaleY(newScaleY);

    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    onElementsChange(elems_copy.slice());
}

export const handleVerticalFlip = (
    elems,
    setElems,
    selectedId,
    selectedItem,
    selectedElement,
    onElementsChange
) => {
    // Flip the vertical scale
    let newScaleX = selectedElement.scaleX();
    let newScaleY = -selectedElement.scaleY();

    const element = {
        ...selectedItem,
        scaleX: newScaleX,
        scaleY: newScaleY
    };

    selectedElement.scaleX(newScaleX);
    selectedElement.scaleY(newScaleY);

    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    onElementsChange(elems_copy.slice());
}
