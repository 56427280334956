import React from 'react';
import PropTypes from 'prop-types';
import {Circle, Group, Image, Layer, Line, Path, Rect, Shape, Stage, Text} from "react-konva";
import COMPONENTS_MAP from '../../SimpleDesigner/COMPONENTS_MAP';
import useImage from "use-image";

export const ProductProductAssetImageStage = (props) => {

    return (
        <Stage
            width={props.template.template_data.print_area_width}
            height={props.template.template_data.print_area_height}
            ref={props.canvasRef}

        >
            <ProductAssetImage
                photoStageRef={props.photoStageRef}
                placement={props.template.placement}
                addToPreviewStageRefs={props.addToPreviewStageRefs}
                width={props.template.template_data.print_area_width}
                height={props.template.template_data.print_area_height}
                templates={props.templates}
                template={props.template}
                selectedPreviewPaneTemplateId={props.index}
                elems={props.elems}
            />

        </Stage>
    )

}

export const ProductAssetImage = (props) => {
    const [background, status] = useImage(props.template.background_url);

    const customerDesignRef = React.useRef();

    const updateCache = () => {
        // https://longviewcoder.com/2021/07/12/konva-group-useful-but-spooky/
        if (customerDesignRef.current) {
            setTimeout(() => {
                let groupClientRect = customerDesignRef.current.getClientRect();

                //if width or height is zero or NaN, then skip

                if (groupClientRect.width === 0 || groupClientRect.height === 0) {
                    customerDesignRef.current.clearCache();
                    return;
                } else {
                    customerDesignRef.current.clearCache();
                    customerDesignRef.current.cache();
                }
            }, 100);
        }
    }

    React.useEffect(() => {
        updateCache();
    }, [props.elems]);

    // Function to determine which Konva component to use based on shape type
    function getShapeComponent(shapeType) {
        switch (shapeType) {
            case 'line':
                return Line;
            case 'path':
                return Path;
            case 'polygon':
                return Line; // Use Konva.Line with closed=true for polygons
            default:
                return Shape; // Fallback or for custom shapes
        }
    }

    // Function to extract and return props for a shape element
    function getChildElemProps(childElem) {
        // console.log('childElem:', childElem)
        // console.log('childElem.shapeType:', childElem.type)
        switch (childElem.type) {
            case 'line':
                return { points: childElem.points, stroke: childElem.stroke, strokeWidth: childElem.strokeWidth };
            case 'path':
                return { data: childElem.data, fill: childElem.fill };
            case 'polygon':
                return { points: childElem.points, fill: childElem.fill, closed: true };
            // Add cases for other shapes as needed
            default:
                return {}; // Default or custom shape props
        }
    }


    return (
        <>
            <Layer>
                <Rect
                    x={0}
                    y={0}
                    width={props.template.template_data.print_area_width}
                    height={props.template.template_data.print_area_height}
                    fill={props.template.template_data.background_color}
                    fillPatternImage={background}
                    opacity={1}
                    listening={false}
                />
            </Layer>
            <Layer
                placement={props.placement}
                //ref={props.addToPreviewStageRefs}
                ref={props.photoStageRef}
                width={props.template.template_data.print_area_height} //
                height={props.template.template_data.print_area_width} //
                print_area_height={props.template.template_data.print_area_height}
                print_area_width={props.template.template_data.print_area_width}
                print_area_top={props.template.template_data.print_area_top}
                print_area_left={props.template.template_data.print_area_left}
                template_height={props.template.template_data.template_height}
                template_width={props.template.template_data.template_width}
            >
                <Group
                    width={props.template.template_data.print_area_width}
                    height={props.template.template_data.print_area_height}
                    ref={customerDesignRef}
                    name={'customerDesign'}
                    //globalCompositeOperation={'hard-light'} // None look great.
                    opacity={1}
                    //scaleX={props.template ? props.width / props.template.template_data.template_width : 0.25}
                    //scaleY={props.template ? props.height / props.template.template_data.template_height : 0.25}
                    clipFunc={(context) => {
                        context.rect(
                            props.template ? props.template.template_data.print_area_left : '0',
                            props.template ? props.template.template_data.print_area_top : '0',
                            props.template ? props.template.template_data.print_area_width : '0',
                            props.template ? props.template.template_data.print_area_height : '0'
                        )
                    }}
                >
                    {props.elem.map((elem, i) => {
                        console.log('elem:', elem);
                        if (elem.type === 'group') {
                            console.log('group:', elem.elems);
                            // Logic to render a group and its children
                            return (
                                <Group
                                    id={elem.id}
                                    set={elem.set}
                                    symmetryType={elem.symmetryType}
                                    key={elem.id + i}
                                    name={'element'}
                                    x={elem.x}
                                    y={elem.y}
                                    width={elem.width}
                                    height={elem.height}
                                    draggable={false}
                                    listening={false}
                                >
                                    {elem.elems.map((childElem) => {
                                            const ShapeComponent = getShapeComponent(childElem.type);

                                            return (
                                                <ShapeComponent
                                                    key={childElem.id}
                                                    data={childElem.data || null}
                                                    fill={childElem.fill}
                                                    scaleX={childElem.scaleX}
                                                    scaleY={childElem.scaleY}
                                                    x={childElem.x}
                                                    y={childElem.y}
                                                    {...getChildElemProps(childElem)}
                                                />
                                            )
                                        }
                                    )}
                                </Group>
                            );
                        } else {
                            const Component = COMPONENTS_MAP[elem.type];
                            if (elem.placement === props.placementRef.current) {
                                return (
                                    <Component
                                        key={i}
                                        data={elem.pathData || null}
                                        name={'element'}
                                        getKey={i}
                                        x={elem.x}
                                        y={elem.y}
                                        width={elem.width}
                                        height={elem.height}
                                        rotation={elem.rotation}
                                        scaleX={elem.scaleX}
                                        scaleY={elem.scaleY}
                                        element={elem}
                                        layerRef={props.drawingLayerRef}
                                        trRef={props.trRef}
                                        shapeProps={elem}
                                        template={props.template}
                                        setSelectedElement={props.setSelectedElement}
                                        isSelected={elem.id === props.selectedId}
                                        getLength={props.elems.length}
                                        draggable={false}
                                    />
                                )
                            }
                        }

                    })}
                </Group>
            </Layer>
        </>
    )
}

export const ProductPhotoStage = (props) => {
    return (
        <Stage
            width={props.template.template_data.template_width * 0.25}
            height={props.template.template_data.template_height * 0.25}
            ref={props.canvasRef}

        >
            <ProductPhoto
                photoStageRef={props.photoStageRef}
                placement={props.template.placement}
                addToPreviewStageRefs={props.addToPreviewStageRefs}
                width={props.template.template_data.template_width * 0.25} //
                height={props.template.template_data.template_height * 0.25} //
                templates={props.templates}
                template={props.template}
                selectedPreviewPaneTemplateId={props.index}
                elems={props.elems}
            />

        </Stage>
    )

}

export const ProductPhoto = (props) => {
    const [background, status] = useImage(props.template.background_url);

    const customerDesignRef = React.useRef();

    const updateCache = () => {
        // https://longviewcoder.com/2021/07/12/konva-group-useful-but-spooky/
        if (customerDesignRef.current) {
            setTimeout(() => {
                let groupClientRect = customerDesignRef.current.getClientRect();

                //if width or height is zero or NaN, then skip

                if (groupClientRect.width === 0 || groupClientRect.height === 0) {
                    //('preview stage w h 0: clear cache')
                    customerDesignRef.current.clearCache();
                    return;
                } else {
                    //console.log('preview stage cache w h: ', groupClientRect.width, groupClientRect.height)
                    customerDesignRef.current.clearCache();
                    customerDesignRef.current.cache();
                }
            }, 100);
        }
    }

    React.useEffect(() => {
        //console.log('Prop Added: ', props.elems);
        updateCache();
    }, [props.elems]);

    return (
        <>
            <Layer>
                <Rect
                    x={0}
                    y={0}
                    width={props.template.template_data.template_width * 0.25}
                    height={props.template.template_data.template_height * 0.25}
                    fill={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.background_color : ''}
                    fillPatternImage={background}
                    opacity={1}
                    listening={false}
                />
            </Layer>
            <Layer
                placement={props.placement}
                //ref={props.addToPreviewStageRefs}
                ref={props.photoStageRef}
                width={props.template.template_data.template_width * 0.25} // {props.width} //{1000} //
                height={props.template.template_data.template_height * 0.25}  // {props.height} //{1000} //
                print_area_height={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_height : ''}
                print_area_width={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_width : ''}
                print_area_top={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_top : ''}
                print_area_left={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_left : ''}
                template_height={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.template_height : ''}
                template_width={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.template_width : ''}
            >
                <Group>
                    <TemplateImage
                        x={0}
                        y={0}
                        width={props.template.template_data.template_width * 0.25}  //  {props.width}
                        height={props.template.template_data.template_height * 0.25}   // {props.height}
                        image_file={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.image_url : '/assets/tshirt.jpg'}
                        //fill={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.background_color : ''}
                        background_url={background}
                    />
                </Group>
                <Group
                    width={props.width}
                    height={props.height}
                    ref={customerDesignRef}
                    name={'customerDesign'}
                    //globalCompositeOperation={'hard-light'} // None look great.
                    opacity={0.7}
                    scaleX={props.templates[props.selectedPreviewPaneTemplateId] ? props.width / props.templates[props.selectedPreviewPaneTemplateId].template_data.template_width : 0.25}
                    scaleY={props.templates[props.selectedPreviewPaneTemplateId] ? props.height / props.templates[props.selectedPreviewPaneTemplateId].template_data.template_height : 0.25}
                    clipFunc={(context) => {
                        context.rect(
                            props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_left : '0',
                            props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_top : '0',
                            props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_width : '0',
                            props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_height : '0'
                        )
                    }}
                >
                    {props.elems.map((elem, i) => {
                        const Component = COMPONENTS_MAP[elem.type]

                        //console.log(props.templates[props.selectedPreviewPaneTemplateId]);
                        if (props.templates[props.selectedPreviewPaneTemplateId]) {
                            if (elem.placement === props.templates[props.selectedPreviewPaneTemplateId].placement) {

                                return (
                                    <Component
                                        key={i}
                                        getKey={i}
                                        element={elem}
                                        layerRef={props.layerRef}
                                        shapeProps={elem}
                                        template={props.template}
                                        getLength={props.elems.length}
                                        listening={false}
                                        draggable={false}
                                        opacity={1}
                                    />
                                )
                            }
                            // set timer for each element
                            setTimeout(() => {
                                //Todo: instead of a timer, create a loaded event for each element
                                //console.log('setTime out element loaded');
                                updateCache();
                            }, 100)
                        }
                    })}
                </Group>
            </Layer>
        </>
    )
}



export const ProductPhotosStage = (props) => {
    return (
        <div className={'ProductPhotoStage'}>
            {props.templates.map((template, index) => {
                //console.log(template);
                return (
                    <Stage
                        key={index}
                        width={template.template_data.template_width}
                        height={template.template_data.template_height}
                    >
                        <ProductPhotos
                            placement={template.placement}
                            addToPreviewStageRefs={props.addToPreviewStageRefs}
                            width={props.width}
                            height={props.height}
                            templates={props.templates}
                            template={template}
                            selectedPreviewPaneTemplateId={index}
                            elems={props.elems}
                        />
                    </Stage>
                )

            })}
        </div>
    )
}

export const PreviewStage = (props) => {
    return (
        <Stage
            width={props.width}
            height={props.height}
            ref={props.previewPaneRef}
        >
            <ProductPhotos
                width={props.width}
                height={props.height}
                templates={props.templates}
                selectedPreviewPaneTemplateId={props.selectedPreviewPaneTemplateId}
                elems={props.elems}
            />
            <Layer>
                <SwitchTemplateButtons
                    templates={props.templates}
                    previewPaneRef={props.previewPaneRef}
                    selectedPreviewPaneTemplateId={props.selectedPreviewPaneTemplateId}
                    setSelectedPreviewPaneTemplateId={props.setSelectedPreviewPaneTemplateId}
                />
            </Layer>
            <Layer>
                <NextPreviousButtons
                    previewPaneRef={props.previewPaneRef}
                    selectedPreviewPaneTemplateId={props.selectedPreviewPaneTemplateId}
                    setSelectedPreviewPaneTemplateId={props.setSelectedPreviewPaneTemplateId}
                    getLength={props.templates.length}
                />
            </Layer>
        </Stage>
    );
}

export const ProductPhotos = (props) => {
    //console.log('ProductPhotos');
    //console.log(props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.background_color : '');
    const [background, status] = useImage(props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.background_url : '');

    const customerDesignRef = React.useRef();

    const updateCache = () => {
        // https://longviewcoder.com/2021/07/12/konva-group-useful-but-spooky/
        if (customerDesignRef.current) {
            setTimeout(() => {
                let groupClientRect = customerDesignRef.current.getClientRect();

                //if width or height is zero or NaN, then skip

                if (groupClientRect.width === 0 || groupClientRect.height === 0) {
                    //console.log('preview stage w h 0: clear cache')
                    customerDesignRef.current.clearCache();
                    return;
                } else {
                    //console.log('preview stage cache w h: ', groupClientRect.width, groupClientRect.height)
                    customerDesignRef.current.clearCache();
                    customerDesignRef.current.cache();
                }
            }, 100);
        }
    }

    // Function to determine which Konva component to use based on shape type
    function getShapeComponent(shapeType) {
        switch (shapeType) {
            case 'line':
                return Line;
            case 'rect':
                return Rect;
            case 'path':
                return Path;
            case 'polygon':
                return Line; // Use Konva.Line with closed=true for polygons
            default:
                return Shape; // Fallback or for custom shapes
        }
    }

    // Function to extract and return props for a shape element
    function getChildElemProps(childElem) {
        // console.log('childElem:', childElem)
        // console.log('childElem.shapeType:', childElem.type)
        switch (childElem.type) {
            case 'line':
                return {points: childElem.points, stroke: childElem.stroke, strokeWidth: childElem.strokeWidth};
            case 'rect':
                return {width: childElem.width, height: childElem.height, fill: childElem.fill, opacity: childElem.opacity};
            case 'path':
                return {data: childElem.data, fill: childElem.fill};
            case 'polygon':
                return {points: childElem.points, fill: childElem.fill, closed: true};
            // Add cases for other shapes as needed
            default:
                return {}; // Default or custom shape props
        }
    }

    React.useEffect(() => {
        //console.log('Prop Added: ', props.elems);
        updateCache();
    }, [props.elems]);

    return (
        <>
            <Layer>
                <Rect
                    x={0}
                    y={0}
                    width={props.width}
                    height={props.height}
                    fill={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.background_color : ''}
                    fillPatternImage={props.templates[props.selectedPreviewPaneTemplateId] ? background : ''}
                    opacity={1}
                    listening={false}
                />
            </Layer>
            <Layer
                placement={props.placement}
                ref={props.addToPreviewStageRefs}
                width={1000} //
                height={1000} //
                print_area_height={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_height : ''}
                print_area_width={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_width : ''}
                print_area_top={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_top : ''}
                print_area_left={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_left : ''}
                template_height={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.template_height : ''}
                template_width={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.template_width : ''}
            >
                <Group>
                    <TemplateImage
                        x={0}
                        y={0}
                        width={props.width}
                        height={props.height}
                        image_file={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.image_url : '/assets/tshirt.jpg'}
                        //fill={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.background_color : ''}
                        //background_url={props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.background_url : ''}
                    />
                </Group>
                <Group
                    width={props.width}
                    height={props.height}
                    ref={customerDesignRef}
                    name={'customerDesign'}
                    //globalCompositeOperation={'hard-light'} // None look great.
                    opacity={0.7}
                    scaleX={props.templates[props.selectedPreviewPaneTemplateId] ? props.width / props.templates[props.selectedPreviewPaneTemplateId].template_data.template_width : 0.25}
                    scaleY={props.templates[props.selectedPreviewPaneTemplateId] ? props.height / props.templates[props.selectedPreviewPaneTemplateId].template_data.template_height : 0.25}
                    clipFunc={(context) => {
                        context.rect(
                            props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_left : '0',
                            props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_top : '0',
                            props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_width : '0',
                            props.templates[props.selectedPreviewPaneTemplateId] ? props.templates[props.selectedPreviewPaneTemplateId].template_data.print_area_height : '0'
                        )
                    }}
                >
                    {props.elems.map((elem, i) => {
                        const Component = COMPONENTS_MAP[elem.type];
                        if (props.templates[props.selectedPreviewPaneTemplateId]) {
                            if (elem.placement === props.templates[props.selectedPreviewPaneTemplateId].placement) {
                                return (
                                    <Component
                                        key={i}
                                        data={elem.pathData || null}
                                        name={'element'}
                                        getKey={i}
                                        x={elem.x}
                                        y={elem.y}
                                        width={elem.width}
                                        height={elem.height}
                                        rotation={elem.rotation}
                                        scaleX={elem.scaleX}
                                        scaleY={elem.scaleY}
                                        element={elem}
                                        layerRef={props.drawingLayerRef}
                                        trRef={props.trRef}
                                        shapeProps={elem}
                                        template={props.template}
                                        setSelectedElement={props.setSelectedElement}
                                        isSelected={elem.id === props.selectedId}
                                        getLength={props.elems.length}
                                        draggable={false}
                                    />
                                )
                            }
                        }
                        // set timer for each element
                        setTimeout(() => {
                            //Todo: instead of a timer, create a loaded event for each element
                            //console.log('setTime out element loaded');
                            updateCache();
                        }, 100)
                    })}
                </Group>
            </Layer>
        </>
    )
}

const NextPreviousButtons = (props) => {

    const handlePreviousClick = () => {
        if (props.selectedPreviewPaneTemplateId > 0) {
            props.setSelectedPreviewPaneTemplateId(props.selectedPreviewPaneTemplateId - 1)
        } else {
            props.setSelectedPreviewPaneTemplateId(props.getLength - 1)
        }
    };

    const handleNextClick = () => {
        if (props.selectedPreviewPaneTemplateId < props.getLength - 1) {
            props.setSelectedPreviewPaneTemplateId(props.selectedPreviewPaneTemplateId + 1)
        } else {
            props.setSelectedPreviewPaneTemplateId(0)
        }
    };

    return (
        <Group>
            <Rect
                // Previous button
                x={0}
                y={0}
                width={30}
                height={props.previewPaneRef.current.height() - 30}
                fill={'#ababab'}
                stroke={'#000'}
                opacity={0.1}
                strokeWidth={0}
                onClick={handlePreviousClick}
                onTap={handlePreviousClick} // Handle mobile touch events
            />
            <Rect
                // Next button
                x={props.previewPaneRef.current.width() - 30}
                y={0}
                width={30}
                height={props.previewPaneRef.current.height() - 30}
                fill={'#ababab'}
                stroke={'#000'}
                opacity={0.1}
                strokeWidth={0}
                onClick={handlePreviousClick}
                onTap={handlePreviousClick} // Handle mobile touch events
            />
            <Text
                x={10}
                y={props.previewPaneRef.current.height() / 2 - 30}
                text={'<'}
                fontSize={20}
                fill={'#000'}
                listening={true}
            />
            <Text
                x={props.previewPaneRef.current.width() - 20}
                y={props.previewPaneRef.current.height() / 2 - 30}
                text={'>'}
                fontSize={20}
                fill={'#000'}
                listening={true}
            />
        </Group>
    )
}

const TemplateImage = (props) => {
    const shapeRef = React.useRef();
    const [image] = useImage(props.image_file, 'Anonymous');
    //const [background, status] = useImage(props.template.background_url);
    const fill = props.fill;
    //const [fillPatternImage] = useImage(props.background_url, 'Anonymous');

    return (
        <Image
            width={props.previewPaneRef ? props.previewPaneRef.current.width() : props.width}
            height={props.previewPaneRef ? props.previewPaneRef.current.height() : props.height}
            image={image}
            ref={shapeRef}
            fill={fill}
            //fillPatternImage={background}
            {...props}
        />
    )
}

const SwitchTemplateButtons = (props) => {
    return (
        <Group>
            <Rect
                x={0}
                y={props.previewPaneRef.current.height() - 30}
                width={props.previewPaneRef.current.width()}
                height={30}
                fill={'#ababab'}
                stroke={'#000'}
                opacity={0.5}
                listening={false}
                strokeWidth={0}
            />
            {props.templates.map((template, i) => {
                return (
                    <Circle
                        key={i}
                        x={(i + 1) * (props.previewPaneRef.current.width() / (props.templates.length + 1))}
                        y={props.previewPaneRef.current.height() - 15}
                        radius={5}
                        fill={props.selectedPreviewPaneTemplateId === i ? '#fff' : '#000'}
                        stroke={props.selectedPreviewPaneTemplateId !== i ? '#fff' : '#000'}
                        strokeWidth={1}
                        onClick={() => {
                            props.setSelectedPreviewPaneTemplateId(i);
                        }}
                    />
                )
            })}
        </Group>
    )
}

export default PreviewStage;