import React, { useState } from 'react';
import {loadElement} from "../lib/assetUtils";
import PhotosAsset from "../SimpleAssets/PhotosAsset";
import IconsAsset from "../SimpleAssets/IconsAsset";
import TextAsset from "../SimpleAssets/TextAsset";
import MyFilesAsset from "../SimpleAssets/MyFilesAsset";
import photosIcon from '../../../../assets/images/icon/photos.svg';
import vectorIcon from '../../../../assets/images/icon/vector.svg';
import textIcon from '../../../../assets/images/icon/text.svg';
import myFilesIcon from '../../../../assets/images/icon/myfiles.svg';

export const SliderPanel = ({
                                isMobileView,
                                isOpen,
                                onClose,
                                mobileIconStyles,
                                isAuthenticated,
                                ...props
                            }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [activeView, setActiveView] = useState('initial');


    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleClose = () => {
        if (!isDragging) {
            onClose();
        }
    };

    return (
        <div className={`slide-up ${isOpen ? 'open' : ''}`}>
            <div className="top-row-close" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={handleClose}>
                Tap here to close
            </div>
            {activeView !== 'initial' && (
                <button onClick={() => setActiveView('initial')}>Back</button>
            )}
            {activeView === 'initial' && (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <button
                            style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                            className={isMobileView ? "mobile_context_button" : ""}
                            onClick={() => setActiveView('photos')}
                        >
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="photos"
                                style={{
                                    ...mobileIconStyles.icon,
                                    // You might want to provide a specific icon for scaling up
                                    backgroundImage: `url(${photosIcon})`
                                }}
                            />
                            <div>Photos</div>
                        </button>
                        <button
                            style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                            className={isMobileView ? "mobile_context_button" : ""}
                            onClick={() => setActiveView('icons')}
                        >
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="Icons"
                                style={{
                                    ...mobileIconStyles.icon,
                                    // And for scaling down
                                    backgroundImage: `url(${vectorIcon})`
                                }}
                            />
                            <div>Icons</div>
                        </button>
                        <button
                            style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                            className={isMobileView ? "mobile_context_button" : ""}
                            onClick={() => setActiveView('text')}
                        >
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="Text"
                                style={{
                                    ...mobileIconStyles.icon,
                                    // And for scaling down
                                    backgroundImage: `url(${textIcon})`
                                }}
                            />
                            <div>Text</div>
                        </button>
                        <button
                            style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                            className={isMobileView ? "mobile_context_button" : ""}
                            onClick={() => setActiveView('myFiles')}
                        >
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="MyFiles"
                                style={{
                                    ...mobileIconStyles.icon,
                                    // And for scaling down
                                    backgroundImage: `url(${myFilesIcon})`
                                }}
                            />
                            <div>My Files</div>
                        </button>
                    </div>
                    
                </div>
            )}
            {activeView === 'photos' && (
                <PhotosAsset
                    isMobileView={isMobileView}
                    mobileIconStyles={mobileIconStyles}
                    loadElement={(e) => loadElement(e, props, activeView, onClose)}
                />
            )}
            {activeView === 'icons' && (
                <IconsAsset
                    isMobileView={isMobileView}
                    mobileIconStyles={mobileIconStyles}
                    loadElement={(e) => loadElement(e, props, activeView, onClose)}
                />
            )}
            {activeView === 'text' && (
                <TextAsset
                    isMobileView={isMobileView}
                    mobileIconStyles={mobileIconStyles}
                    loadElement={(e) => loadElement(e, props, activeView, onClose)}
                    printAreaRef={props.printAreaRef}
                    stageRef={props.stageRef}
                    placementRef={props.placementRef}
                    elems={props.elems}
                    setElems={props.setElems}
                    selectedElement={props.selectedElement}
                    setSelectedElement={props.setSelectedElement}
                    setSelectedId={props.setSelectedId}
                    onClose={onClose}
                />
            )}
            {activeView === 'myFiles' && (
                <MyFilesAsset
                    isMobileView={isMobileView}
                    loadElement={(e) => loadElement(e, props, activeView, onClose)}
                    isAuthenticated={isAuthenticated}
                />
            )}

        </div>
    );
}

