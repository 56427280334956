const ZOOM_SENSITIVITY = 0.001;  // Adjust this value to make zooming more or less sensitive

export const updateElementThumbnail = async (element, elems, setElems) => {
    try {
        // Asynchronously generate the data URL
        const thumbnailDataURL = await generateThumbnail(element);

        // Update the state with the resolved data URL
        const updatedElems = elems.map((elem) => {
            if (elem.id === element.id) {
                return {...elem, thumbnail: thumbnailDataURL};
            }
            return elem;
        });

        // Set the state with the updated array
        setElems(updatedElems);
    } catch (error) {
        console.error("Error generating or updating thumbnail:", error);
    }
};

// thumbnailUtils.js
export const generateThumbnail = async (element) => {
    const thumbnailWidth = 80;
    const thumbnailHeight = 80;

    return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            canvas.width = thumbnailWidth;
            canvas.height = thumbnailHeight;
            const context = canvas.getContext('2d');

            // Handle different types directly
            if (element.type === 'image') {
                // Draw the image on the canvas
                const img = new Image();
                img.crossOrigin = 'Anonymous'; // Handle CORS
                img.onload = () => {
                    context.clearRect(0, 0, thumbnailWidth, thumbnailHeight);
                    context.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);
                    resolve(canvas.toDataURL()); // Return the data URL
                };
                img.onerror = () => reject("Failed to load image for thumbnail");
                img.src = element.data; // Ensure the data source is valid
            } else if (element.type === 'path') {
                // Special handling for paths
                context.fillStyle = 'black';
                context.font = 'bold 40px Arial';
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.clearRect(0, 0, thumbnailWidth, thumbnailHeight);
                context.fillText('T', thumbnailWidth / 2, thumbnailHeight / 2);
                resolve(canvas.toDataURL());
            } else if (element.type === 'freehand') {
                // Calculate the minimum and maximum X/Y coordinates
                const points = element.points || [];
                let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
                for (let i = 0; i < points.length; i += 2) {
                    minX = Math.min(minX, points[i]);
                    maxX = Math.max(maxX, points[i]);
                    minY = Math.min(minY, points[i + 1]);
                    maxY = Math.max(maxY, points[i + 1]);
                }

                // Determine the original drawing's width and height
                const originalDrawingWidth = maxX - minX;
                const originalDrawingHeight = maxY - minY;

                // Calculate the scaling factors
                const scaleX = thumbnailWidth / originalDrawingWidth;
                const scaleY = thumbnailHeight / originalDrawingHeight;

                // Clear the canvas
                context.clearRect(0, 0, thumbnailWidth, thumbnailHeight);
                context.beginPath();
                for (let i = 0; i < points.length; i += 2) {
                    // Normalize and scale the points
                    const x = (points[i] - minX) * scaleX;
                    const y = (points[i + 1] - minY) * scaleY;

                    if (i === 0) {
                        context.moveTo(x, y);
                    } else {
                        context.lineTo(x, y);
                    }
                }

                context.strokeStyle = element.stroke || '#000';
                context.lineWidth = element.strokeWidth || 2;
                context.lineCap = 'round';
                context.lineJoin = 'round';
                context.stroke();

                const dataURL = canvas.toDataURL();
                resolve(dataURL);
            } else if (element.type === 'pattern') {
                const thumbnailWidth = 80;
                const thumbnailHeight = 80;
                const design = element.design || [];

                // Check if `design` is a valid array
                if (!Array.isArray(design)) {
                    console.error("Invalid design attribute for pattern element");
                    resolve('');
                    return;
                }

                // Calculate bounding box for normalization
                let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
                design.forEach((shape) => {
                    if (shape.type === 'line' && Array.isArray(shape.points)) {
                        // Handle lines separately since they don't have `x` and `y` properties
                        shape.points.forEach((point, index) => {
                            if (index % 2 === 0) {
                                minX = Math.min(minX, point);
                                maxX = Math.max(maxX, point);
                            } else {
                                minY = Math.min(minY, point);
                                maxY = Math.max(maxY, point);
                            }
                        });
                    } else if (shape.x !== undefined && shape.y !== undefined) {
                        minX = Math.min(minX, shape.x);
                        minY = Math.min(minY, shape.y);

                        if (shape.type === 'rect') {
                            maxX = Math.max(maxX, shape.x + (shape.width || 0));
                            maxY = Math.max(maxY, shape.y + (shape.height || 0));
                        } else if (shape.type === 'circle') {
                            maxX = Math.max(maxX, shape.x + (shape.radius || 0) * 2);
                            maxY = Math.max(maxY, shape.y + (shape.radius || 0) * 2);
                        } else if (shape.type === 'polygon' && shape.radius !== undefined) {
                            maxX = Math.max(maxX, shape.x + (shape.radius || 0) * 2);
                            maxY = Math.max(maxY, shape.y + (shape.radius || 0) * 2);
                        } else if (shape.type === 'star') {
                            maxX = Math.max(maxX, shape.x + (shape.outerRadius || 0) * 2);
                            maxY = Math.max(maxY, shape.y + (shape.outerRadius || 0) * 2);
                        } else if (shape.type === 'image') {
                            maxX = Math.max(maxX, shape.x + (shape.width || 0));
                            maxY = Math.max(maxY, shape.y + (shape.height || 0));
                        }
                    }
                });

                // Check if a valid bounding box was found
                if (minX === Infinity || minY === Infinity || maxX === -Infinity || maxY === -Infinity) {
                    console.error("Could not determine bounding box for pattern element");
                    resolve('');
                    return;
                }

                // Normalize coordinates and set scaling factors
                const originalWidth = maxX - minX;
                const originalHeight = maxY - minY;
                const scaleX = thumbnailWidth / originalWidth;
                const scaleY = thumbnailHeight / originalHeight;

                // Clear canvas and draw shapes
                context.clearRect(0, 0, thumbnailWidth, thumbnailHeight);
                design.forEach((shape) => {
                    let x = 0, y = 0;

                    // Normalize shape coordinates
                    if (shape.type !== 'line') {
                        x = (shape.x - minX) * scaleX;
                        y = (shape.y - minY) * scaleY;
                    }

                    context.beginPath();
                    if (shape.type === 'rect') {
                        context.fillStyle = shape.fill || '#000';
                        context.strokeStyle = shape.stroke || '#000';
                        context.lineWidth = shape.strokeWidth || 1;
                        context.rect(x, y, shape.width * scaleX, shape.height * scaleY);
                        context.fill();
                        context.stroke();
                    } else if (shape.type === 'line' && Array.isArray(shape.points)) {
                        const points = shape.points.map((point, index) =>
                            index % 2 === 0 ? (point - minX) * scaleX : (point - minY) * scaleY
                        );
                        context.moveTo(points[0], points[1]);
                        for (let i = 2; i < points.length; i += 2) {
                            context.lineTo(points[i], points[i + 1]);
                        }
                        context.strokeStyle = shape.stroke || '#000';
                        context.lineWidth = shape.strokeWidth || 1;
                        context.lineCap = shape.lineCap || 'round';
                        context.lineJoin = shape.lineJoin || 'round';
                        context.setLineDash(shape.dash || []);
                        context.stroke();
                    } else if (shape.type === 'circle') {
                        context.arc(x, y, shape.radius * Math.min(scaleX, scaleY), 0, 2 * Math.PI);
                        context.fillStyle = shape.fill || '#000';
                        context.strokeStyle = shape.stroke || '#000';
                        context.lineWidth = shape.strokeWidth || 1;
                        context.fill();
                        context.stroke();
                    } else if (shape.type === 'polygon') {
                        const sides = shape.sides || 5;
                        const radius = (shape.radius || 0) * Math.min(scaleX, scaleY);
                        const angleStep = (2 * Math.PI) / sides;
                        context.moveTo(x + radius * Math.cos(0), y - radius * Math.sin(0));
                        for (let i = 1; i <= sides; i++) {
                            const angle = i * angleStep;
                            const pointX = x + radius * Math.cos(angle);
                            const pointY = y - radius * Math.sin(angle);
                            context.lineTo(pointX, pointY);
                        }
                        context.closePath();
                        context.fillStyle = shape.fill || '#000';
                        context.strokeStyle = shape.stroke || '#000';
                        context.lineWidth = shape.strokeWidth || 1;
                        context.fill();
                        context.stroke();
                    } else if (shape.type === 'star') {
                        const numPoints = shape.numPoints || 5;
                        const innerRadius = (shape.innerRadius || 0) * Math.min(scaleX, scaleY);
                        const outerRadius = (shape.outerRadius || 0) * Math.min(scaleX, scaleY);
                        const step = Math.PI / numPoints;
                        context.moveTo(x + outerRadius, y);
                        for (let i = 0; i < 2 * numPoints; i++) {
                            const radius = i % 2 === 0 ? outerRadius : innerRadius;
                            const angle = i * step;
                            context.lineTo(x + radius * Math.cos(angle), y - radius * Math.sin(angle));
                        }
                        context.closePath();
                        context.fillStyle = shape.fill || '#000';
                        context.strokeStyle = shape.stroke || '#000';
                        context.lineWidth = shape.strokeWidth || 1;
                        context.fill();
                        context.stroke();
                    } else if (shape.type === 'image' && shape.src) {
                        const img = new Image();
                        img.crossOrigin = 'Anonymous';
                        img.onload = () => {
                            context.drawImage(img, x, y, shape.width * scaleX, shape.height * scaleY);
                        };
                        img.src = shape.src || '';
                    }
                });

                resolve(canvas.toDataURL());
            } else {
                // Default fallback for other types
                context.fillStyle = '#ccc';
                context.fillRect(0, 0, thumbnailWidth, thumbnailHeight);
                resolve(canvas.toDataURL());
            }
        }
    )
        ;
};


const handleElementZoomIn = (node, prevZoomValue, pinchDist, lastPinchDist) => {
    console.log("handleElementZoomIn");
    let prevZoom = prevZoomValue || node.scaleX();
    let zoomChange = pinchDist && lastPinchDist ? (pinchDist - lastPinchDist) * ZOOM_SENSITIVITY : 0.1;
    let zoomFactor = Math.min(1.3, 1 + zoomChange);  // Ensuring zoomFactor is within a reasonable range
    let scaleValue = prevZoom * zoomFactor;
    scaleValue = Math.min(scaleValue, 3);

    node.scale({x: scaleValue, y: scaleValue});
    node.getLayer().batchDraw();
};

const handleElementZoomOut = (node, prevZoomValue, pinchDist, lastPinchDist) => {
    console.log("handleElementZoomOut");
    let prevZoom = prevZoomValue || node.scaleX();
    let zoomChange = pinchDist && lastPinchDist ? (lastPinchDist - pinchDist) * ZOOM_SENSITIVITY : 0.1;
    let zoomFactor = Math.max(0.7, 1 - zoomChange);  // Ensuring zoomFactor is within a reasonable range
    let scaleValue = prevZoom * zoomFactor;
    //scaleValue = Math.max(scaleValue, 0.5);

    node.scale({x: scaleValue, y: scaleValue});
    node.getLayer().batchDraw();
};


export const handlePinchAndRotateMove = (e, node, initialPinchDistance, setInitialPinchDistance) => {
    if (e.evt.touches.length === 2 && node) {
        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];
        const distance = Math.sqrt(
            Math.pow(touch2.clientX - touch1.clientX, 2) +
            Math.pow(touch2.clientY - touch1.clientY, 2)
        );

        if (distance > initialPinchDistance) {
            console.log('Element Zoom in detected');
            handleElementZoomIn(node, initialPinchDistance, distance, initialPinchDistance);
        } else {
            console.log('Element Zoom out detected');
            handleElementZoomOut(node, initialPinchDistance, distance, initialPinchDistance);
        }
        setInitialPinchDistance(distance);

    }
};


export const handlePinchAndRotateEnd = (setInitialPinchDistance) => {
    setInitialPinchDistance(null);
};

export const handleTap = (e, lastTap, setLastTap) => {
    console.log("handleTap")
    const currentTime = Date.now();
    const tapLength = currentTime - lastTap;
    if (tapLength < 500 && tapLength > 0) {
        // It's a double tap
        onSelect(e);
    }
    setLastTap(currentTime);
};

export const handleTouchStart = (e, pressTimer, setPressTimer, touchStartPos) => {
    // ignore if two fingers touched screen
    if (e.evt.touches.length !== 1) {
        // for long press
        touchStartPos.current = {x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY};
        setPressTimer(setTimeout(() => {
            console.log("long press");
            // Bring up the context menu or other options
        }, 1000));
    }
};

export const handleTouchMove = (e, pressTimer, touchStartPos) => {
    // for long press
    const touchPos = {x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY};
    if (Math.abs(touchPos.x - touchStartPos.current.x) > 10 || Math.abs(touchPos.y - touchStartPos.current.y) > 10) {
        clearTimeout(pressTimer);
    }
};


export const handleTouchEnd = (pressTimer) => {
    // for long press
    clearTimeout(pressTimer);
};
