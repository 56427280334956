import React, { useState } from 'react';
import { SimpleProductPicker } from "../SimpleProductPicker/SimpleProductPicker";

export const ProductPickerSliderPanel = ({ isMobileView, isOpen, onClose, ...props }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleClose = () => {
        if (!isDragging) {
            onClose();
        }
    };

    return (
        <div className={`slide-up ${isOpen ? 'open' : ''}`}>
            <div className="top-row-close" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={handleClose}>
                Tap here to close
            </div>
            <SimpleProductPicker
                setSelectedDesignId={props.setSelectedDesignId}
                setExternalProductId={props.setExternalProductId}
                setExternalVariantId={props.setExternalVariantId}
                setSelectedColor={props.setSelectedColor}
                productTypeId={props.productTypeId}
                setProductTypeId={props.setProductTypeId}
                loadProduct={props.loadProduct}
                availableColors={props.availableColors}
                categoryId={props.categoryId}
                setCategoryId={props.setCategoryId}
                isMobileView={isMobileView}
                onClose={onClose}
            />
        </div>
    );
}
