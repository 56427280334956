import React from 'react';

import { ArrangeControlsContent, ArrangeControls, handleMoveToBottom, handleMoveToTop, handleMoveDown, handleMoveUp} from "./ArrangeControls";
import { CloneControls } from "./CloneControls";
import { ColorControls, ColorControlsContent } from "./ColorControls";
import { FontControls, FontControlsContent } from "./FontControls";
import { FontFormattingControls, FontFormattingControlsContent } from "./FontFormattingControls";
import { TextTransformsControlsContent } from "./TextTransformsControls";

import {buildColorMap, updateSVGColors} from "./propertiesUtils";
import { EffectControls, EffectsControlsContent } from "./EffectControls";
import { FlipControls, FlipControlsContent, handleVerticalFlip, handleHorizontalFlip } from "./FlipControls";
import { LockControls } from "./LockControls";
import { RemoveControls } from "./RemoveControls";
import { RotateControls, RotateControlsContent, handleRotateRight, handleRotateLeft } from "./RotateControls";
import { ScaleControls, ScaleControlsContent, handleScaleUp, handleScaleDown } from "./ScaleControls";
import { Modal, Button } from 'react-bootstrap';
import SVGEditorWrapper from "../SVGEdit/SVGEditorWrapper";
import { OptionSlider } from "../mobile/OptionSlider";
import svgEditorIcon from '../../../../assets/images/icon/photo-editing.svg';
import arrangeIcon from "../../../../assets/images/icon/arrange.svg";
import {useSelectionContext} from "../../contexts/SelectionContext";



export const PropertiesPanel = (props) =>
{
    const {
        selectedElement,
        selectedId,
        selectedItem,
        setSelectedElement,
    } = useSelectionContext();

    const [isModalOpen, setModalOpen] = React.useState(false);

    function removeForeignObjectAndSwitch(svgString) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgString, "text/xml");

        // Remove the foreignObject element
        const foreignObject = xmlDoc.querySelector('foreignObject');
        if (foreignObject) {
            foreignObject.parentNode.removeChild(foreignObject);
        }

        // Remove the switch elements
        const switchElements = xmlDoc.querySelectorAll('switch');
        switchElements.forEach(switchElement => {
            // Move all children of the switch element up one level in the DOM tree
            while (switchElement.firstChild) {
                switchElement.parentNode.insertBefore(switchElement.firstChild, switchElement);
            }
            // Remove the now empty switch element
            switchElement.parentNode.removeChild(switchElement);
        });

        // Serialize back to string
        const serializer = new XMLSerializer();
        const updatedSvgString = serializer.serializeToString(xmlDoc);

        return updatedSvgString;
    }


    // Retrieve SVG content from the selected SVG element
    const getSelectedSVGContent = () => {
        //console.log("Selected Element Ref:", props.selectedElement);
        //console.log("Selected Item:", props.selectedItem);

        if (selectedElement) {
            return selectedItem;
        }
        return '';
    };


    // SVG content to be edited
    const [svgToEdit, setSvgToEdit] = React.useState('');
    const [elemToEdit, setElemToEdit] = React.useState('');

    const handleOpenEditor = () => {
        let elem = getSelectedSVGContent();

        // Log the received element and its SVG content
        //console.log("Element to Edit:", elem);
        //console.log("SVG Content:", elem.svgXML);

        // Remove foreignObject from the SVG content
        const cleanedSvgContent = removeForeignObjectAndSwitch(elem.svgXML);

        //console.log("Cleaned SVG Content:", cleanedSvgContent);

        // Set states for modal and editor content with the cleaned SVG
        setElemToEdit(elem);
        setSvgToEdit(cleanedSvgContent);

        setModalOpen(true);
    };

    const [isOptionsSliderOpen, setIsOptionsSliderOpen] = React.useState(false);
    const [activeControl, setActiveControl] = React.useState(null);


    const handleButtonClick = (controlType) => {
        setIsOptionsSliderOpen(true);
        setActiveControl(controlType);
    }


    const handleOptionsSliderClose = () => {
        setIsOptionsSliderOpen(false);
        setActiveControl(null);
    }

    const mobileIconStyles = {
        mobile: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80px',
            backgroundColor: 'none',
            color: '#000',
            fontSize: '14px',
            cursor: 'pointer',
        },
        desktop: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            height: '50px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            fontSize: '15px',
            cursor: 'pointer',
            padding: '5px'
        },
        icon: {
            width: '40px',
            height: '40px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
        desktopIcon: {
            width: '15px',
            height: '15px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            marginBottom: '5px'  // Adds a little space between the icon and text
        }
    };


    if (!selectedId){
        if (props.isMobileView === true) {
            return null;
        } else {
            return (
                <div className={'row'}>
                    <p>Select an element in the design to change its properties</p>
                </div>
            )
        }
    } else {
        return(
            <>
                { isOptionsSliderOpen ? null : (
                    <>
                        {selectedItem &&
                        selectedItem['format'] === 'svg'
                        && (
                        <ColorControls
                            isMobileView={props.isMobileView}
                            props={props}
                            onButtonClick={() => handleButtonClick('svgColors')}
                            mobileIconStyles={mobileIconStyles}
                        />
                        )
                        }
                        {
                            selectedItem &&
                            selectedItem['type'] === 'text'
                            && (<ColorControls
                                    isMobileView={props.isMobileView}
                                    selectedId={selectedId}
                                    selectedItem={selectedItem}
                                    selectedElement={selectedElement}
                                    props={props}
                                    onButtonClick={() => handleButtonClick('svgColors')}
                                    mobileIconStyles={mobileIconStyles}
                                />
                            )
                        }
                        {
                            selectedItem &&
                            selectedItem['type'] === 'path'
                            && (<ColorControls
                                    isMobileView={props.isMobileView}
                                    selectedId={selectedId}
                                    selectedItem={selectedItem}
                                    selectedElement={selectedElement}
                                    props={props}
                                    onButtonClick={() => handleButtonClick('svgColors')}
                                    mobileIconStyles={mobileIconStyles}
                                />
                            )
                        }
                        {
                            selectedItem && selectedItem['type'] === 'text'
                            && (
                                <FontControls
                                    isMobileView={props.isMobileView}
                                    //props={props}
                                    onButtonClick={() => handleButtonClick('fonts')}
                                    mobileIconStyles={mobileIconStyles}
                                    elems={props.elems}
                                    setElems={props.setElems}
                                    selectedId={selectedId}
                                    selectedItem={selectedItem}
                                    selectedElement={selectedElement}
                                    onElementsChange={props.onElementsChange}
                                    //trRef={props.trRef}
                                    setSelectedElement={setSelectedElement}

                                />
                            )
                        }
                        {
                            selectedItem && selectedItem['type'] === 'text'
                            && (
                                <FontFormattingControls
                                    isMobileView={props.isMobileView}
                                    //props={props}
                                    onButtonClick={() => handleButtonClick('fontFormatting')}
                                    mobileIconStyles={mobileIconStyles}
                                    elems={props.elems}
                                    setElems={props.setElems}
                                    selectedId={selectedId}
                                    selectedItem={selectedItem}
                                    selectedElement={selectedElement}
                                    onElementsChange={props.onElementsChange}
                                    //trRef={props.trRef}
                                    setSelectedElement={setSelectedElement}

                                />
                            )
                        }
                        {
                            selectedItem && selectedItem['type'] === 'text'
                            && (
                                <TextTransformsControlsContent
                                    isMobileView={props.isMobileView}
                                    //props={props}
                                    onButtonClick={() => handleButtonClick('fontFormatting')}
                                    mobileIconStyles={mobileIconStyles}
                                    elems={props.elems}
                                    setElems={props.setElems}
                                    selectedId={selectedId}
                                    selectedItem={selectedItem}
                                    selectedElement={selectedElement}
                                    onElementsChange={props.onElementsChange}
                                    //trRef={props.trRef}
                                    setSelectedElement={setSelectedElement}

                                />
                            )
                        }
                        {selectedItem['type'] !== 'text' && <ScaleControls
                            isMobileView={props.isMobileView}
                            onButtonClick={() => handleButtonClick('scale')}
                            mobileIconStyles={mobileIconStyles}
                            elems={props.elems}
                            setElems={props.setElems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            selectedElement={selectedElement}
                            onElementsChange={props.onElementsChange}
                            //trRef={props.trRef}
                            setSelectedElement={setSelectedElement}
                        />}
                        {selectedId &&
                            selectedItem['format'] === 'svg'
                            && (
                            <Button onClick={handleOpenEditor}>
                                <div
                                    data-icon="svgEditor"
                                    style={{
                                        ...mobileIconStyles.icon,
                                        backgroundImage: `url(${svgEditorIcon})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                />
                                <div
                                    style={{
                                        width: 'auto',
                                    }}
                                >Edit Icon</div>
                            </Button>
                        )}

                        <Modal
                            show={isModalOpen}
                            onHide={() => setModalOpen(false)}
                            size="xl"
                            dialogClassName="modal-90w modal-90h"
                            className="fullHeightModal"
                            centered
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header>
                                <Modal.Title>SVG Editor</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <SVGEditorWrapper
                                    svgContent={svgToEdit}
                                    elemToEdit={elemToEdit}
                                    elems={props.elems}
                                    setElems={props.setElems}
                                    selectedId={selectedId}
                                    selectedItem={selectedItem}
                                    selectedElement={selectedElement}
                                    onClose={() => setModalOpen(false)}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                        <FlipControls
                            isMobileView={props.isMobileView}
                            onButtonClick={() => handleButtonClick('flip')}
                            mobileIconStyles={mobileIconStyles}
                            props={props}
                        />
                        <ArrangeControls
                            isMobileView={props.isMobileView}
                            onButtonClick={() => handleButtonClick('arrange')}
                            mobileIconStyles={mobileIconStyles}
                            props={props}
                        />
                        <RotateControls
                            isMobileView={props.isMobileView}
                            onButtonClick={() => handleButtonClick('rotate')}
                            mobileIconStyles={mobileIconStyles}
                            props={props}
                        />
                        <CloneControls
                            isMobileView={props.isMobileView}
                            elems={props.elems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            selectedElement={selectedElement}
                            onElementsChange={props.onElementsChange}
                            mobileIconStyles={mobileIconStyles}
                            //trRef={props.trRef}
                            setSelectedElement={setSelectedElement}
                        />
                        <RemoveControls
                            isMobileView={props.isMobileView}
                            elems={props.elems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            selectedElement={selectedElement}
                            onElementsChange={props.onElementsChange}
                            mobileIconStyles={mobileIconStyles}
                            //trRef={props.trRef}
                            setSelectedElement={setSelectedElement}
                        />
                    </>
                )}


                {/*<EffectControls
                    isMobileView={props.isMobileView}
                    elems={props.elems}
                    selectedId={props.selectedId}
                    selectedItem={props.selectedItem}
                    selectedElement={props.selectedElement}
                    onElementsChange={props.onElementsChange}
                    onButtonClick={() => handleButtonClick('effects')}

                    mobileIconStyles={mobileIconStyles}
                />*/}

                {/*<LockControls
                    isMobileView={props.isMobileView}
                    elems={props.elems}
                    selectedId={props.selectedId}
                    selectedItem={props.selectedItem}
                    selectedElement={props.selectedElement}
                    onElementsChange={props.onElementsChange}
                    mobileIconStyles={mobileIconStyles}
                />*/}

                {isOptionsSliderOpen && <OptionSlider isVisible={isOptionsSliderOpen} onClose={handleOptionsSliderClose}>
                    {activeControl === 'fonts' && (
                        <FontControlsContent
                            elems={props.elems}
                            setElems={props.setElems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            onElementsChange={props.onElementsChange}
                            selectedElement={selectedElement}
                            mobileIconStyles={mobileIconStyles}
                            isMobileView={props.isMobileView}
                        />
                    )}
                    {activeControl === 'fontFormatting' && (
                        <FontFormattingControlsContent
                            elems={props.elems}
                            setElems={props.setElems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            onElementsChange={props.onElementsChange}
                            selectedElement={selectedElement}
                            mobileIconStyles={mobileIconStyles}
                            isMobileView={props.isMobileView}
                        />
                    )}
                    {activeControl === 'scale' && (
                        <ScaleControlsContent
                            elems={props.elems}
                            setElems={props.setElems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            onElementsChange={props.onElementsChange}
                            selectedElement={selectedElement}
                            mobileIconStyles={mobileIconStyles}
                            isMobileView={props.isMobileView}
                        />
                    )}
                    {activeControl === 'flip' && (
                        <FlipControlsContent
                            elems={props.elems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            selectedElement={selectedElement}
                            onFlipVertical={handleVerticalFlip}
                            onFlipHorizontal={handleHorizontalFlip}
                            onElementsChange={props.onElementsChange}
                            mobileIconStyles={mobileIconStyles}
                            isMobileView={props.isMobileView}
                        />)}
                    {activeControl === 'arrange' && (
                        <ArrangeControlsContent
                            elems={props.elems}
                            setElems={props.setElems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            selectedElement={selectedElement}
                            onMoveUp={handleMoveUp}
                            onMoveDown={handleMoveDown}
                            onMoveToTop={handleMoveToTop}
                            onMoveToBottom={handleMoveToBottom}
                            onElementsChange={props.onElementsChange}
                            mobileIconStyles={mobileIconStyles}
                            isMobileView={props.isMobileView}
                            placementRef={props.placementRef}
                            //trRef={props.trRef}
                        />)}
                    {activeControl === 'svgColors' && (
                        <ColorControlsContent
                            elems={props.elems}
                            setElems={props.setElems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            selectedElement={selectedElement}
                            onElementsChange={props.onElementsChange}
                            mobileIconStyles={mobileIconStyles}
                            isMobileView={props.isMobileView}
                            placementRef={props.placementRef}
                            buildColorMap={buildColorMap}
                            updateSVGColors={updateSVGColors}
                        />)}
                    {activeControl === 'rotate' && (
                        <RotateControlsContent
                            elems={props.elems}
                            setElems={props.setElems}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            selectedElement={selectedElement}
                            onRotateRight={handleRotateRight}
                            onRotateLeft={handleRotateLeft}
                            onElementsChange={props.onElementsChange}
                            mobileIconStyles={mobileIconStyles}
                            isMobileView={props.isMobileView}
                            placementRef={props.placementRef}
                        />)}
                </OptionSlider>}
            </>
        );
    }
}



/*
function handleCloseBorderColorPicker() {
    setDisplayBorderColorPicker(false);
    const element = {...props.selectedItem, stroke: borderColor};
    const index = props.elems.findIndex((arrItem) => arrItem.id === props.selectedId);
    let elems_copy = props.elems.slice();
    elems_copy.splice(index, 1, element);
    props.onElementsChange(elems_copy.slice());
};

function handleChangeBorderColor(color) {
    const elementBorderColor = color.hex;
    setBorderColor(elementBorderColor);
    props.selectedElement.stroke(elementBorderColor);
    //props.selectedElement.parent.draw();

    lodash.debounce(() => {
        const element = {...props.selectedItem, fill: elementBorderColor};
        const index = props.elems.findIndex((arrItem) => arrItem.id === props.selectedId);
        let elems_copy = props.elems.slice();
        elems_copy.splice(index, 1, element);
        props.onElementsChange(elems_copy.slice());
    }, 100);
};

function onBorderThicknessChange(e){
    let borderSize = Number(e.target.value);
    const element = {...props.selectedItem, strokeWidth: borderSize};
    const index = props.elems.findIndex((arrItem) => arrItem.id === props.selectedId);
    let elems_copy = props.elems.slice();
    elems_copy.splice(index, 1, element);
    setStrokeWidth(e.target.value);
    props.onElementsChange(elems_copy.slice());
}

function handleBorderColorPicker() {
    setDisplayBorderColorPicker(!displayBorderColorPicker);
};
*/



