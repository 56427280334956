import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from "react-bootstrap";
import WebFontLoader from "webfontloader";
import fontsIcon from "../../../../assets/images/icon/lettering.svg";
import FONTS_WITH_TAGS from "./FONTS_WITH_TAGS";

export const useFonts = (selectedItem, elems, onElementsChange, selectedId) => {
    const [availableFonts, setAvailableFonts] = useState([]);

    useEffect(() => {
        WebFontLoader.load({
            google: {
                families: [
                    "Open Sans",
                    "Roboto",
                    "Merriweather",
                    "Lato",
                    "Montserrat",
                    "Raleway",
                    "Rubik",
                    "Neuton",
                    "Cardo",
                    "Spectral",
                    "Proza Libre",
                    "Inknut Antiqua",
                    "IBM Plex Serif",
                    "Alegreya",
                    "Libre Baskerville",
                    "Playfair Display",
                    "PT Serif",
                    "Noto Serif",
                    "Arvo",
                    "Abril Fatface",
                    "Bitter",
                    "Crimson Text",
                    "Eczar",
                    "Fira Sans"
                ]
            },
            fontactive: function (familyName) {
                setAvailableFonts(prevFonts => [...prevFonts, familyName]);
            }
        });
    }, []);

    const handleFontChange = (e, selectedFont) => {
        e.stopPropagation();
        const updatedFontFamily = selectedFont;
        const element = {
            ...selectedItem,
            fontFamily: updatedFontFamily
        };

        // Create an off-screen canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set the font to the new font and the size from the element
        ctx.font = `${element.fontSize}px ${updatedFontFamily}`;

        // Measure the text width
        const textMetrics = ctx.measureText(element.text);

        // Adjust the width of the element
        element.width = textMetrics.width;

        const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
        let elemsCopy = [...elems];
        elemsCopy[index] = element;

        onElementsChange(elemsCopy);
    };


    return {
        availableFonts,
        handleFontChange
    };
}



export const FontControls = ({
                                 selectedItem,
                                 onElementsChange,
                                 elems,
                                 selectedId,
                                 mobileIconStyles,
                                 isMobileView,
                                 onButtonClick,
                                 selectedElement,
                             }) => {
    const { availableFonts, handleFontChange } = useFonts(selectedItem, elems, onElementsChange, selectedId);
    const [selectedTag, setSelectedTag] = useState("All Fonts");
    const getFontsByTag = (tag) => {
        return FONTS_WITH_TAGS.filter(font => font.tags.includes(tag)).map(font => font.name);
    };

    if (isMobileView) {
        return (
            <button
                style={mobileIconStyles.mobile}
                className="mobile_context_button"
                onClick={onButtonClick}
            >
                <div
                    className="mobile_context_button_icon"
                    data-icon="Fonts"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${fontsIcon})`
                    }}
                />
                <div>Fonts</div>
            </button>
        );
    } else {
        return (
            <Dropdown>
                <Dropdown.Toggle>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <div
                            className={isMobileView ? "mobile_context_button_icon" : ""}
                            data-icon="Fonts"
                            style={{
                                ...mobileIconStyles.desktopIcon,
                                backgroundImage: `url(${fontsIcon})`,
                            }}
                        />
                        Fonts
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{maxWidth: '450px'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', height: '200px' }}>
                        <div style={{ minWidth: '150px', borderRight: '1px solid #ddd', padding: '10px', overflowY: 'auto' }}>
                            {Array.from(new Set(FONTS_WITH_TAGS.flatMap(font => font.tags))).map(tag => (
                                <div
                                    key={tag}
                                    onClick={() => setSelectedTag(tag)}
                                    style={{ padding: '5px 10px', cursor: 'pointer', backgroundColor: selectedTag === tag ? '#eee' : 'transparent' }}
                                >
                                    {tag}
                                </div>
                            ))}
                        </div>
                        <div style={{flexGrow: 1, overflowY: 'auto'}}>
                            {getFontsByTag(selectedTag).map(font => (
                                <Dropdown.Item
                                    key={font}
                                    onClick={(e) => handleFontChange(e, font)}
                                    active={selectedItem === font}
                                    style={{fontFamily: font, fontSize: 36}}
                                >
                                    {font}
                                </Dropdown.Item>
                            ))}
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
};

export const FontControlsContent = ({
                                        selectedItem,
                                        onElementsChange,
                                        elems,
                                        selectedId,
                                    }) => {
    const { availableFonts, handleFontChange } = useFonts(selectedItem, elems, onElementsChange, selectedId);
    const [selectedTag, setSelectedTag] = useState("All Fonts");
    const getFontsByTag = (tag) => {
        return FONTS_WITH_TAGS.filter(font => font.tags.includes(tag)).map(font => font.name);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', maxHeight: '200px' }}>
            {/* Font Categories */}
            <div style={{ minWidth: '150px', borderRight: '1px solid #ddd', padding: '10px', overflowY: 'auto' }}>
                {Array.from(new Set(FONTS_WITH_TAGS.flatMap(font => font.tags))).map(tag => (
                    <div
                        key={tag}
                        onClick={() => setSelectedTag(tag)}
                        style={{
                            padding: '5px 10px',
                            cursor: 'pointer',
                            backgroundColor: selectedTag === tag ? '#eee' : 'transparent'
                        }}
                    >
                        {tag}
                    </div>
                ))}
            </div>

            {/* Fonts List */}
            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                {getFontsByTag(selectedTag).map(font => (
                    <div
                        key={font}
                        onClick={(e) => handleFontChange(e, font)}
                        style={{
                            fontFamily: font,
                            fontSize: 36,
                            padding: '5px 10px',
                            cursor: 'pointer',
                            backgroundColor: selectedItem === font ? '#eee' : 'transparent'
                        }}
                    >
                        {font}
                    </div>
                ))}
            </div>
        </div>
    );
}
