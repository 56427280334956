import {Button, Dropdown} from "react-bootstrap";
import React from "react";
import arrangeIcon from '../../../../assets/images/icon/arrange.svg';
import arrangeMoveUpIcon from '../../../../assets/images/icon/arrange-moveUp.svg';
import arrangeMoveDownIcon from '../../../../assets/images/icon/arrange-moveDown.svg';
import arrangeMoveToTopIcon from '../../../../assets/images/icon/arrange-moveTop.svg';
import arrangeMoveToBottomIcon from '../../../../assets/images/icon/arrange-moveBottom.svg';



export const ArrangeControls = ({
                                    isMobileView,
                                    onButtonClick,
                                    mobileIconStyles,
                                    props
                                }) => {

    if (isMobileView) {
        return (
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={onButtonClick}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Arrange"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${arrangeIcon})`
                    }}
                />
                <div>Arrange</div>
            </button>
        )
    }

    else {
        return (
            <Dropdown>
                <Dropdown.Toggle>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div
                            className={isMobileView ? "mobile_context_button_icon" : ""}
                            data-icon="Arrange"
                            style={{
                                ...mobileIconStyles.desktopIcon,
                                backgroundImage: `url(${arrangeIcon})`,
                            }}
                        />
                        Arrange
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Button onClick={() => handleMoveToTop(
                        props.elems,
                        props.setElems,
                        props.selectedId,
                        props.selectedItem,
                        props.selectedElement,
                        props.onElementsChange,
                        props.placementRef,
                        props.trRef
                    )}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="MoveToTop"
                                style={{
                                    ...mobileIconStyles.icon,
                                    width: '20px',
                                    height: '20px',
                                    backgroundImage: `url(${arrangeMoveToTopIcon})`,
                                    marginRight: '5px'
                                }}
                            />
                            Move to Top
                        </div>
                    </Button>
                    <Button onClick={() => handleMoveToBottom(
                        props.elems,
                        props.setElems,
                        props.selectedId,
                        props.selectedItem,
                        props.selectedElement,
                        props.onElementsChange,
                        props.placementRef,
                        props.trRef
                    )}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="MoveToBottom"
                                style={{
                                    ...mobileIconStyles.icon,
                                    width: '20px',
                                    height: '20px',
                                    backgroundImage: `url(${arrangeMoveToBottomIcon})`,
                                    marginRight: '5px'
                                }}
                            />
                            Move to Bottom
                        </div>
                    </Button>
                    <Button onClick={() => handleMoveUp(
                        props.elems,
                        props.setElems,
                        props.selectedId,
                        props.selectedItem,
                        props.selectedElement,
                        props.onElementsChange,
                        props.placementRef,
                        props.trRef
                    )}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="MoveUp"
                                style={{
                                    ...mobileIconStyles.icon,
                                    width: '20px',
                                    height: '20px',
                                    backgroundImage: `url(${arrangeMoveUpIcon})`,
                                    marginRight: '5px'
                                }}
                            />
                            Move Up
                        </div>
                    </Button>
                    <Button onClick={() => handleMoveDown(
                        props.elems,
                        props.setElems,
                        props.selectedId,
                        props.selectedItem,
                        props.selectedElement,
                        props.onElementsChange,
                        props.placementRef,
                        props.trRef
                    )}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="MoveDown"
                                style={{
                                    ...mobileIconStyles.icon,
                                    width: '20px',
                                    height: '20px',
                                    backgroundImage: `url(${arrangeMoveDownIcon})`,
                                    marginRight: '5px'
                                }}
                            />
                            Move Down
                        </div>
                    </Button>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}


export const ArrangeControlsContent = ({
                                           elems,
                                           setElems,
                                           selectedId,
                                           selectedItem,
                                           onElementsChange,
                                           selectedElement,
                                           onMoveUp,
                                           onMoveDown,
                                           onMoveToTop,
                                           onMoveToBottom,
                                           mobileIconStyles,
                                           isMobileView,
                                           placementRef,
                                           trRef
                                       }) => (
    <div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={() => onMoveUp(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange,
                    placementRef,
                    trRef
                )}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="MoveUp"
                    style={{
                        ...mobileIconStyles.icon,
                        // You might want to provide a specific icon for scaling up
                        backgroundImage: `url(${arrangeMoveUpIcon})`
                    }}
                />
                <div>Move Up</div>
            </button>
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={() => onMoveDown(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange,
                    placementRef,
                    trRef
                )}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="MoveDown"
                    style={{
                        ...mobileIconStyles.icon,
                        // And for scaling down
                        backgroundImage: `url(${arrangeMoveDownIcon})`
                    }}
                />
                <div>Move Down</div>
            </button>
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={() => onMoveToTop(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange,
                    placementRef,
                    trRef
                )}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="MoveToTop"
                    style={{
                        ...mobileIconStyles.icon,
                        // And for scaling down
                        backgroundImage: `url(${arrangeMoveToTopIcon})`
                    }}
                />
                <div>Move To Top</div>
            </button>
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={() => onMoveToBottom(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange,
                    placementRef,
                    trRef
                )}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="MoveToBottom"
                    style={{
                        ...mobileIconStyles.icon,
                        // And for scaling down
                        backgroundImage: `url(${arrangeMoveToBottomIcon})`
                    }}
                />
                <div>Move to Bottom</div>
            </button>
        </div>
    </div>
);

export const handleMoveToBottom = (elems,
                                   setElems,
                                   selectedId,
                                   selectedItem,
                                   selectedElement,
                                   onElementsChange,
                                   placementRef,
                                   trRef
) => {
    console.log("handleMoveToTop")
    // Filter out elements with the same placement as the selected element
    const samePlacementElements = elems.filter(e => e.placement === placementRef.current);

    // Find the index of the selected element within the filtered list
    const indexInSamePlacement = samePlacementElements.findIndex(e => e.id === selectedId);

    // Move the selected element to the top of the filtered list
    const movedElement = samePlacementElements.splice(indexInSamePlacement, 1)[0];
    samePlacementElements.unshift(movedElement);

    // Merge the updated filtered list with the rest of the elements
    const updatedElems = elems
        .filter(e => e.placement !== placementRef.current)
        .concat(samePlacementElements);

    if (selectedElement.current) {
        trRef.current.nodes([selectedElement.current]);
        trRef.current.getLayer().batchDraw();
    }


    // Update state and trigger any additional necessary changes
    setElems(updatedElems);
    onElementsChange(updatedElems);
}


export const handleMoveToTop = (elems,
                                setElems,
                                selectedId,
                                selectedItem,
                                selectedElement,
                                onElementsChange,
                                placementRef,
                                trRef
) => {
    // Filter out elements with the same placement as the selected element
    const samePlacementElements = elems.filter(e => e.placement === placementRef.current);

    // Find the index of the selected element within the filtered list
    const indexInSamePlacement = samePlacementElements.findIndex(e => e.id === selectedId);

    // Move the selected element to the end of the filtered list (i.e. the top layer)
    const movedElement = samePlacementElements.splice(indexInSamePlacement, 1)[0];
    samePlacementElements.push(movedElement);

    // Merge the updated filtered list with the rest of the elements
    const updatedElems = elems
        .filter(e => e.placement !== placementRef.current)
        .concat(samePlacementElements);

    if (selectedElement.current) {
        trRef.current.nodes([selectedElement.current]);
        trRef.current.getLayer().batchDraw();
    }


    // Update state and trigger any additional necessary changes
    setElems(updatedElems);
    onElementsChange(updatedElems);
}


export const handleMoveDown = (elems,
                               setElems,
                               selectedId,
                               selectedItem,
                               selectedElement,
                               onElementsChange,
                               placementRef,
                               trRef
) => {
    // Filter out elements with the same placement as the selected element
    const samePlacementElements = elems.filter(e => e.placement === placementRef.current);

    // Find the index of the selected element within the filtered list
    const indexInSamePlacement = samePlacementElements.findIndex(e => e.id === selectedId);

    // Check if the selected element is already at the bottom-most position
    if (indexInSamePlacement <= 0) {
        return;
    }

    // Swap the selected element with the one below it
    [samePlacementElements[indexInSamePlacement], samePlacementElements[indexInSamePlacement - 1]] =
        [samePlacementElements[indexInSamePlacement - 1], samePlacementElements[indexInSamePlacement]];

    // Merge the updated filtered list with the rest of the elements
    const updatedElems = elems
        .filter(e => e.placement !== placementRef.current)
        .concat(samePlacementElements);

    if (selectedElement.current) {
        trRef.current.nodes([selectedElement.current]);
        trRef.current.getLayer().batchDraw();
    }

    // Update state and trigger any additional necessary changes
    setElems(updatedElems);
    onElementsChange(updatedElems);
}


export const handleMoveUp = (elems,
                             setElems,
                             selectedId,
                             selectedItem,
                             selectedElement,
                             onElementsChange,
                             placementRef,
                             trRef) => {
    // Filter out elements with the same placement as the selected element
    const samePlacementElements = elems.filter(e => e.placement === placementRef.current);

    // Find the index of the selected element within the filtered list
    const indexInSamePlacement = samePlacementElements.findIndex(e => e.id === selectedId);

    // Check if the selected element is already at the top-most position
    if (indexInSamePlacement >= samePlacementElements.length - 1) {
        return;
    }

    // Swap the selected element with the one above it
    [samePlacementElements[indexInSamePlacement], samePlacementElements[indexInSamePlacement + 1]] =
        [samePlacementElements[indexInSamePlacement + 1], samePlacementElements[indexInSamePlacement]];

    // Merge the updated filtered list with the rest of the elements
    const updatedElems = elems
        .filter(e => e.placement !== placementRef.current)
        .concat(samePlacementElements);

    if (selectedElement.current) {
        trRef.current.nodes([selectedElement.current]);
        trRef.current.getLayer().batchDraw();
    }

    // Update state and trigger any additional necessary changes
    setElems(updatedElems);
    onElementsChange(updatedElems);
}
