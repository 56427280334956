import React from "react";
import {Button, Dropdown} from "react-bootstrap";
import resizeIcon from '../../../../assets/images/icon/scale.svg';
import scaleUpIcon from '../../../../assets/images/icon/scale-up.svg';
import scaleDownIcon from '../../../../assets/images/icon/scale-down.svg';

export const ScaleControls = ({
                                  isMobileView,
                                  onButtonClick,
                                  mobileIconStyles,
    elems,
    setElems,
    selectedId,
    selectedItem,
    onElementsChange,
    selectedElement,
                              }) => {

    if (isMobileView) {
        return (
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={onButtonClick}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Scale22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${resizeIcon})`
                    }}
                />
                <div>Scale</div>
            </button>
        );
    }
    else {
        return (
            <Dropdown>
                <Dropdown.Toggle>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div
                            className={isMobileView ? "mobile_context_button_icon" : ""}
                            data-icon="Scale22"
                            style={{
                                ...mobileIconStyles.desktopIcon,
                                backgroundImage: `url(${resizeIcon})`,
                            }}
                        />
                        Scale
                    </div>
                </Dropdown.Toggle>


                <Dropdown.Menu>
                    <ScaleUpButton
                        elems={elems}
                        setElems={setElems}
                        selectedId={selectedId}
                        selectedItem={selectedItem}
                        selectedElement={selectedElement}
                        onElementsChange={onElementsChange}
                        isMobileView={isMobileView}
                        mobileIconStyles={mobileIconStyles}
                        scaleUpIcon={scaleUpIcon}
                    />

                    <ScaleDownButton
                        elems={elems}
                        setElems={setElems}
                        selectedId={selectedId}
                        selectedItem={selectedItem}
                        selectedElement={selectedElement}
                        onElementsChange={onElementsChange}
                        isMobileView={isMobileView}
                        mobileIconStyles={mobileIconStyles}
                        scaleDownIcon={scaleDownIcon}
                    />
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export const ScaleUpButton = (props) => {

    const handleScaleUp = () => {

        // Fetch the most recent attributes directly from the Konva node
        const currentAttributes = props.selectedElement.getAttrs();

        // Scale up the selected item by 10%
        const scaleFactor = 1.1; // 10% increment
        let width = currentAttributes.width * scaleFactor;
        let height = currentAttributes.height * scaleFactor;

        // Construct the updated element attributes
        const element = {
            ...props.selectedItem,
            width: width,
            height: height
        };

        props.selectedElement.width(element.width);
        props.selectedElement.height(element.height);

        // Update the elems array with the modified element
        const index = props.elems.findIndex((arrItem) => arrItem.id === props.selectedId);
        let elems_copy = props.elems.slice();
        elems_copy.splice(index, 1, element);

        // Update the state in the parent component
        //props.onElementsChange(elems_copy.slice());
        props.setElems(elems_copy.slice());

        props.selectedElement.getLayer().batchDraw();

    };

    return (
        <Button onClick={handleScaleUp}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className={props.isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Scale22"
                    style={{
                        ...props.mobileIconStyles.icon,
                        width: '20px',
                        height: '20px',
                        backgroundImage: `url(${props.scaleUpIcon})`,
                        marginRight: '5px' // Adds a little space between the icon and text
                    }}
                />
                {' '} Scale Up
            </div>
        </Button>
    )
}

const ScaleDownButton = (props) => {
    const handleScaleDown = () => {
        const currentAttributes = props.selectedElement.getAttrs();
        const scaleFactor = 0.9;
        let width = currentAttributes.width * scaleFactor;
        let height = currentAttributes.height * scaleFactor;

        const element = {
            ...props.selectedItem,
            width: width,
            height: height
        };

        props.selectedElement.width(element.width);
        props.selectedElement.height(element.height);

        const index = props.elems.findIndex((arrItem) => arrItem.id === props.selectedId);
        let elems_copy = props.elems.slice();
        elems_copy.splice(index, 1, element);

        props.onElementsChange(elems_copy.slice());
    };

    return (
        <Button onClick={handleScaleDown}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className={props.isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Scale22"
                    style={{
                        ...props.mobileIconStyles.icon,
                        width: '20px',
                        height: '20px',
                        backgroundImage: `url(${props.scaleDownIcon})`,
                        marginRight: '5px' // Adds a little space between the icon and text
                    }}
                />
                {' '}
                Scale Down
            </div>
        </Button>
    );
};




export const ScaleControlsContent = ({
                                         elems,
                                         setElems,
                                         selectedId,
                                         selectedItem,
                                         onElementsChange,
                                         selectedElement,
                                         mobileIconStyles,
                                         isMobileView
                                     }) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <ScaleUpButton
                elems={elems}
                setElems={setElems}
                selectedId={selectedId}
                selectedItem={selectedItem}
                selectedElement={selectedElement}
                onElementsChange={onElementsChange}
                isMobileView={isMobileView}
                mobileIconStyles={mobileIconStyles}
                scaleUpIcon={scaleUpIcon}
            />
            <ScaleDownButton
                elems={elems}
                setElems={setElems}
                selectedId={selectedId}
                selectedItem={selectedItem}
                selectedElement={selectedElement}
                onElementsChange={onElementsChange}
                isMobileView={isMobileView}
                mobileIconStyles={mobileIconStyles}
                scaleDownIcon={scaleDownIcon}
            />
        </div>
    )}




