import React from "react";

export const Swatch = (props) => {

    const swatchStyle = {
        boxShadow: props.isSelected ? '0 0 0 3px rgba(1,1,1,1)' : '0 0 0 1px rgba(0,0,0,.1)', // If selected, apply a thicker border
        display: 'inline-block',
        cursor: 'pointer',
        marginRight: '5px'
    };

    return(
        <div>
            <div style={swatchStyle} onClick={ props.handleFillColorClick }>
                <div data-colorkey={props.cssClass} style={{'background': props.fillValue, 'width': '40px', 'height': '40px'}} />
            </div>
        </div>
    )
}