import React, {useState} from 'react';
import { PropertiesPanel } from "../SimpleElementOptions/PropertiesPanel";
import { SliderPanel } from "./SliderPanel";
import { ProductPickerSliderPanel } from "./ProductPickerSliderPanel";
import { PreviewerSliderPanel } from "./PreviewerSliderPanel";
import AxiosHeaders from "../AxiosHeaders";

export const ContextualPanel = ({
                                    stageRef,
                                    printAreaRef,
                                    printedAreaRef,
                                    placementRef,
                                    template,
                                    selectedId,
                                    selectedItem,
                                    selectedElement,
                                    elems,
                                    setElems,
                                    onElementsChange,
                                    trRef,
                                    setSelectedElement,
                                    setSelectedId,
                                    ...props
                                }) => {

    const [isAddSliderOpen, setIsAddSliderOpen] = React.useState(false);
    const [isProductPickerSliderOpen, setIsProductPickerSliderOpen] = React.useState(false);
    const [isPreviewerSliderOpen, setIsPreviewerSliderOpen] = React.useState(false);
    const isAnySliderOpen = isAddSliderOpen || isProductPickerSliderOpen || isPreviewerSliderOpen;

    // The following unloads the properties panel when the user opens a slider panel
    // It helps in Safari where the Canvas overlaps the slider panel if the properties panel is open
    // We may want to revisit this but for now it works and I don't see a downside.
    const [isPropertiesPanelVisible, setIsPropertiesPanelVisible] = useState(true);

    const isUserGuest = props.isGuest === "true" ? true : false;
    const [isAuthenticated, setIsAuthenticated] = useState(!isUserGuest);

    React.useEffect(() => {
        const handleUserAuthentication = () => {
            AxiosHeaders();
            setIsAuthenticated(true);
        };

        window.addEventListener('userAuthenticated', handleUserAuthentication);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('userAuthenticated', handleUserAuthentication);
        };
    }, []);

    const mobileIconStyles = {
        mobile: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80px',
            backgroundColor: 'none',
            color: '#000',
            fontSize: '14px',
            cursor: 'pointer',
        },
        desktop: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            height: '50px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            fontSize: '15px',
            cursor: 'pointer',
            padding: '5px'
        },
        icon: {
            width: '40px',
            height: '40px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        }
    };

    const handleAddClick = () => {
        setIsAddSliderOpen(true);
        setIsPropertiesPanelVisible(false);
    };

    const handleCloseSlideUp = () => {
        setIsAddSliderOpen(false);
        setIsPropertiesPanelVisible(true);
    };

    const handleProductPickerClick = () => {
        setIsProductPickerSliderOpen(true);
        setIsPropertiesPanelVisible(false);
    };

    const closeProductPickerSlider = () => {
        setIsProductPickerSliderOpen(false);
        setIsPropertiesPanelVisible(true);
    };

    const handlePreviewerClick = () => {
        setIsPreviewerSliderOpen(true);
        setIsPropertiesPanelVisible(false);
    };

    const closePreviewerSlider = () => {
        setIsPreviewerSliderOpen(false);
        setIsPropertiesPanelVisible(true);
    };

    const handleScroll = (event) => {
        const container = event.target;
        if (event.deltaY > 0) {
            container.scrollLeft += 20;
        } else {
            container.scrollLeft -= 20;
        }
    };

    return (
        <div className="contextual-panel" onWheel={handleScroll}>
            {isAnySliderOpen ? null : (

                <div className="bottom-row">
                    <div className="round-btn-container">
                        <button className="round-btn" onClick={handleAddClick}>
                            Add
                        </button>
                        <button className="round-btn" onClick={handleProductPickerClick}>
                            Product Picker
                        </button>
                        <button className="round-btn" onClick={handlePreviewerClick}>
                            Preview
                        </button>
                        {isPropertiesPanelVisible && selectedId && (<PropertiesPanel
                            stageRef={stageRef}
                            printAreaRef={printAreaRef}
                            placementRef={placementRef}
                            template={template}
                            selectedId={selectedId}
                            selectedItem={selectedItem}
                            selectedElement={selectedElement}
                            elems={elems}
                            setElems={setElems}
                            onElementsChange={onElementsChange}
                            isMobileView={true}
                            trRef={trRef}
                            setSelectedElement={setSelectedElement}
                        />)}
                    </div>
                </div>
            )}

            {isAddSliderOpen && (<SliderPanel
                isMobileView={true}
                isOpen={isAddSliderOpen}
                onClose={handleCloseSlideUp}
                mobileIconStyles={mobileIconStyles}
                placementRef={placementRef}
                printAreaRef={printAreaRef}
                printedAreaRef={printedAreaRef}
                selectedElement={selectedElement}
                setSelectedElement={setSelectedElement}
                elems={elems}
                setElems={setElems}
                stageRef={stageRef}
                isAuthenticated={isAuthenticated}
                setSelectedId={setSelectedId}
            />)}
            {isProductPickerSliderOpen && (<ProductPickerSliderPanel
                isMobileView={true}
                isOpen={isProductPickerSliderOpen}
                onClose={closeProductPickerSlider}
                mobileIconStyles={mobileIconStyles}
                setSelectedDesignId={props.setSelectedDesignId}
                setExternalProductId={props.setExternalProductId}
                setExternalVariantId={props.setExternalVariantId}
                setSelectedColor={props.setSelectedColor}
                productTypeId={props.productTypeId}
                setProductTypeId={props.setProductTypeId}
                loadProduct={props.loadProduct}
                availableColors={props.availableColors}
                categoryId={props.categoryId}
                setCategoryId={props.setCategoryId}
            />)}
            {isPreviewerSliderOpen && (<PreviewerSliderPanel
                isMobileView={true}
                isOpen={isPreviewerSliderOpen}
                onClose={closePreviewerSlider}
                product_id={props.product_id}
                previewStageRef={props.previewStageRef}
                previewPaneRef={props.previewPaneRef}
                elems={elems}
                templates={props.templates}
                selectedPreviewPaneTemplateId={props.selectedPreviewPaneTemplateId}
                setSelectedPreviewPaneTemplateId={props.setSelectedPreviewPaneTemplateId}
                setProductPhotoURLs={props.setProductPhotoURLs}
            />)}

        </div>
);
}
