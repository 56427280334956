import {base64encode, calculateViewportCenter, extractStyleTagRules} from "./utils";
import setAxiosHeaders from "./AxiosHeaders";
import axios from "axios";

export const loadElement = (e, props, activeView, selectElement, onClose=()=>{}) => {
    //onClose=()=>{} default empty function for onClose when not passed in as a parameter in desktop mode
    return new Promise((resolve, reject) => {

        e.preventDefault();
        e.stopPropagation();

        //const type = e.target.attributes.getNamedItem('data-itemtype').value;

        const type = activeView.toLowerCase(); // activeView is the name of the asset type (e.g., "Photos", "Icons", "Text", etc.)

        const data = e.target.attributes.getNamedItem('data-itemdata').value;
        const s3Url = e.target.attributes.getNamedItem('data-itemdatas3url').value;
        const format = e.target.attributes.getNamedItem('data-itemformat').value;
        const element_id = e.target.attributes.getNamedItem('data-itemid').value;
        const placement = props.placementRef.current;
        const dimensionsOfPrintedArea = props.printedAreaRef.current.getClientRect({relativeTo: props.stageRef.current});

        switch(type) {
            case 'Templates'.toLowerCase():
                loadTemplate(data, format, placement);
                resolve(true);
                break;
            case 'Text'.toLowerCase():
                // Text loads directly from TextAsset.jsx
                loadText(e, props, data, format, placement);
                break;
            case 'Photos'.toLowerCase():
                loadPhoto(e, props, element_id, data, s3Url, 'image', format, placement, dimensionsOfPrintedArea, selectElement)
                    .then(() => {
                        setTimeout(() => {
                            onClose();
                            resolve(true);
                        }, 300);
                    })
                    .catch(err => {
                        console.error('Error in loadPhoto', err);
                        reject(err);
                    });
                break;
            case 'Icons'.toLowerCase():
                loadIcon(e, props, data, 'image', format, placement, dimensionsOfPrintedArea, selectElement)
                    .then(() => {
                        setTimeout(() => {
                        onClose();
                        resolve(true);
                        }, 300);
                    })
                    .catch(err => {
                        console.error('Error in loadIcon', err);
                        reject(err);
                    });
                break;
            case 'My Files'.toLowerCase().replace(/\s/g, ''):
                loadPhoto(e, props, element_id, data, s3Url, 'image', format, placement, dimensionsOfPrintedArea, selectElement)
                    .then(() => {
                        setTimeout(() => {
                            onClose();
                            resolve(true);
                        }, 300);
                    })
                    .catch(err => {
                        console.error('Error in loadPhoto', err);
                        reject(err);
                    });
                break;
            case 'my files':
                loadPhoto(e, props, element_id, data, s3Url, 'image', format, placement, dimensionsOfPrintedArea, selectElement)
                    .then(() => {
                        setTimeout(() => {
                            onClose();
                            resolve(true);
                        }, 300);
                    })
                    .catch(err => {
                        console.error('Error in loadPhoto', err);
                        reject(err);
                    });
                break;
            case 'Products'.toLowerCase():
                loadProduct(e, data, format, placement);
                resolve(true);
                break;
            case 'Digital':
                loadDigital(data, format, placement);
                resolve(true);
                break;
            default:
                console.error("Unrecognized asset type:", type);
                resolve(true);
                break;
        }
    });
};

const loadTemplate = (data, format, placement) => {
};

const loadText = (e, props, data, format, placement) => {
    e.preventDefault();
    // Text loads directly from TextAsset.jsx

};


const loadPhoto = (e, props, element_id, data, s3Url, type, format, placement, dimensions, selectElement) => {
    e.preventDefault();
//    return Promise.resolve();
    return new Promise(async (resolved, reject) => {

        try {
            // Decide on a ratio for image size (e.g., 0.5 means the image will take up half of the viewport's width/height)
            const ratio = 0.5;

            // Calculate the center of the current viewport
            let designView = document.querySelector("#DesignView");
            let containerWidth = designView.offsetWidth;
            let containerHeight = designView.offsetHeight;

            const { centerX, centerY } = calculateViewportCenter(props.printAreaRef, props.stageRef, containerWidth, containerHeight);

            // Get the natural dimensions of the image
            const img = new Image();
            img.src = s3Url;
            await new Promise((resolve) => {
                img.onload = resolve;
                img.onerror = () => {
                    reject(new Error("Image failed to load."));
                };
            });

            const naturalWidth = img.naturalWidth;
            const naturalHeight = img.naturalHeight;

            // Calculate aspect ratio
            const aspectRatio = naturalWidth / naturalHeight;

            // Calculate desired width and height based on aspect ratio
            let photoWidth, photoHeight;
            if (naturalWidth > naturalHeight) {
                photoWidth = containerWidth * ratio;
                photoHeight = photoWidth / aspectRatio;
            } else {
                photoHeight = containerHeight * ratio;
                photoWidth = photoHeight * aspectRatio;
            }

            let elem = {
                element_id: element_id,
                x: centerX - photoWidth / 2,
                y: centerY - photoHeight / 2,
                width: photoWidth * 3,
                height: photoHeight * 3,
                rotation: 0,
                scale: { x: 1, y: 1 },
                type: type,
                data: data,
                s3Url: s3Url,
                id: `${type}${props.elems.length + 1}`,
                placement: placement,
            };
            if (format === 'raster') {

                elem['type'] = type;

                const elems = props.elems.concat([elem]);
                props.setElems(elems);
                selectElement(elem.id, 1000);

                resolved();
            } else {
                elem['type'] = type;
                elem['strokeWidth'] = 10;
                elem['fill'] = 'red';
                elem['stroke'] = 'black';
                const elems = props.elems.concat([elem]);
                props.setElems(elems);
                selectElement(elem.id, 1000);
                resolved();

            }

        } catch (error) {
            console.error("error: " + error);
            reject(error);
        }
    });
};

const loadIcon = (e, props, data, type, format, placement, dimensionsOfPrintedArea, selectElement) => {
    e.preventDefault();

    return new Promise(async (resolve, reject) => {

        try {
            // Decide on a ratio for icon size (e.g., 0.5 means the icon will take up half of the viewport's width/height)
            const ratio = 0.8;

            // Calculate the center of the current viewport
            let designView = document.querySelector("#DesignView");
            let containerWidth = designView.offsetWidth;
            let containerHeight = designView.offsetHeight;

            const { centerX, centerY } = calculateViewportCenter(props.printAreaRef, props.stageRef, containerWidth, containerHeight);

            // Initialize the base properties of the element
            let elem = {
                x: centerX,
                y: centerY,
                rotation: 0,
                scale: { x: 1, y: 1 },
                type: type,
                data: data,
                id: `${type}${props.elems.length + 1}`,
                placement: placement,
            };

            if (format === 'svg') {
                setAxiosHeaders();
                const app = axios.create({
                    baseURL: data,
                    withCredentials: false,
                });

                app.get(data)
                    .then((response) => {
                        const SVG = response.data;
                        let styles = $('style', SVG);
                        let fills = [];

                        if (styles.length > 0) {
                            let cssRules = extractStyleTagRules(styles[0].innerHTML);
                            for (const property in cssRules) {
                                if (`${cssRules[property]['selectorText']}` !== `undefined`) {
                                    let colorObj = {};
                                    const selectorText = `${cssRules[property]['selectorText']}`;
                                    const fill = `${cssRules[property]['style']['fill']}`;
                                    colorObj[selectorText] = fill;
                                    fills.push(colorObj);
                                }
                            }
                        } else {
                            let cssRules = {};
                        }

                        const url = 'data:image/svg+xml;base64,' + base64encode(SVG);

                        const aspectRatio = 1;

                        // Calculate desired width and height based on aspect ratio
                        let iconWidth, iconHeight;
                        if (containerWidth > containerHeight) {
                            iconWidth = containerWidth * ratio;
                            iconHeight = iconWidth / aspectRatio;
                        } else {
                            iconHeight = containerHeight * ratio;
                            iconWidth = iconHeight * aspectRatio;
                        }

                        elem['x'] = centerX - iconWidth / 2;
                        elem['y'] = centerY - iconHeight / 2;
                        elem['width'] = iconWidth;
                        elem['height'] = iconHeight;
                        elem['original'] = data;
//                        elem['svgFills'] = fills;
                        elem['svgXML'] = SVG;
                        elem['format'] = format;
                        elem['data'] = url;
                        const elems = props.elems.concat([elem]);
                        props.setElems(elems);
                        selectElement(elem.id);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else if (format === 'raster') {
                elem['type'] = type;
                const elems = props.elems.concat([elem]);
                selectElement(elem.id);
                props.setElems(elems);
            } else {
                elem['type'] = type;
                elem['strokeWidth'] = 10;
                elem['fill'] = 'red';
                elem['stroke'] = 'black';
                const elems = props.elems.concat([elem]);
                selectElement(elem.id);
                props.setElems(elems);
            }
            resolve();
        } catch (error) {
            console.error(error);
            reject(error)
        }
    });
};


/* const loadIcon = (e, data, type, format, placement, dimensionsOfPrintedArea) => {
   e.preventDefault();

   let elem = {
     x: getRandomInt(dimensionsOfPrintedArea.x / props.scale.x, (dimensionsOfPrintedArea.x + 100) / props.scale.x),
     y: getRandomInt(dimensionsOfPrintedArea.y / props.scale.y, (dimensionsOfPrintedArea.y + 100) / props.scale.y),
     //width: 300,
     //height: 300,
     rotation: 0,
     scale: { x: 1, y: 1 },
     type: type,
     data: data,
     id: `${type}${props.elems.length + 1}`,
     placement: placement,
   };

   if (format === 'svg') {

     setAxiosHeaders();

     const app = axios.create({
       baseURL: data,
       withCredentials: false,
     })

     app.get(data)
         .then((response) => {
           const SVG = response.data;
           let styles = $('style', SVG);
           let fills = [];

           if (styles.length > 0) {
             let cssRules = extractStyleTagRules(styles[0].innerHTML);
             for (const property in cssRules) {
               if (`${cssRules[property]['selectorText']}` !== `undefined`) {
                 let colorObj = {};
                 const selectorText = `${cssRules[property]['selectorText']}`;
                 const fill = `${cssRules[property]['style']['fill']}`;
                 colorObj[selectorText] = fill;
                 fills.push(colorObj);
               }
             }
           } else {
             let cssRules = {};
           }

           const url = 'data:image/svg+xml;base64,' + base64encode(SVG);
           elem['original'] = data;
           elem['svgFills'] = fills;
           elem['svgXML'] = SVG;
           elem['format'] = format;
           elem['data'] = url;
           const elems = props.elems.concat([elem]);
           props.setElems(elems);
         })
         .catch((error) => {
           console.log(error);
         });
   } else if (format == 'raster') {
     elem['type'] = type;
     const elems = props.elems.concat([elem]);
     props.setElems(elems);
   } else {
     elem['type'] = type;
     elem['strokeWidth'] = 10;
     elem['fill'] = 'red';
     elem['stroke'] = 'black';
     const elems = props.elems.concat([elem]);
     props.setElems(elems);
   }
 };*/

const loadProduct = (e, data, format, placement) => {
    const productTypeId = e.target.attributes.getNamedItem(
        'data-itemProductTypeId'
    ).value;
    const type = e.target.attributes.getNamedItem('data-itemtype').value;
    const title = e.target.attributes.getNamedItem('data-itemTitle').value;
    const externalId = e.target.attributes.getNamedItem(
        'data-itemExternalId'
    ).value;

    props.setProductTypeId(productTypeId);
    props.setExternalProductId(externalId);

};

const loadDigital = (data, format, placement) => {
};

