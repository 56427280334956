const FONTS_WITH_TAGS = [
    {
        name: "Open Sans",
        tags: ["All Fonts", "Sans Serif"]
    },
    {
        name: "Roboto",
        tags: ["All Fonts", "Sans Serif"]
    },
    {
        name: "Merriweather",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Lato",
        tags: ["All Fonts", "Sans Serif"]
    },
    {
        name: "Montserrat",
        tags: ["All Fonts", "Sans Serif"]
    },
    {
        name: "Raleway",
        tags: ["All Fonts", "Sans Serif"]
    },
    {
        name: "Rubik",
        tags: ["All Fonts", "Sans Serif"]
    },
    {
        name: "Proza Libre",
        tags: ["All Fonts", "Sans Serif"]
    },
    {
        name: "Fira Sans",
        tags: ["All Fonts", "Sans Serif"]
    },
    {
        name: "Neuton",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Cardo",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Spectral",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "IBM Plex Serif",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Alegreya",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Libre Baskerville",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Playfair Display",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "PT Serif",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Noto Serif",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Arvo",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Bitter",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Crimson Text",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Eczar",
        tags: ["All Fonts", "Serif"]
    },
    {
        name: "Abril Fatface",
        tags: ["All Fonts", "Vintage"]
    }
];

export default FONTS_WITH_TAGS;