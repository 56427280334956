import React, {createContext, useContext, useEffect, useState} from 'react';
import {updateSelectionRect} from "../components/SimpleDesigner/utils";
import Konva from "konva";
import {useSnapping} from "../components/SimpleDesigner/hooks/useSnapping";

// Create a context for the selection state
// This context will be used to manage the selection state of the elements.
// This way we can avoid prop-drilling the selection state to all the components
// that need to access it. We can also have everything related to selections in
// one place. This makes it easier to manage and maintain the selection state.

const SelectionContext = createContext();

export const useSelectionContext = () => useContext(SelectionContext);

export const SelectionProvider = ({ children, ...props }) => {
    // const trRef = React.useRef(null); // Transformer Ref
    // Note that trRef had to be declared in a parent and prop-drilled
    // down to this component as well as all the way down to <Transformer>
    // in the Konva elements.

    //  Since refs themselves don't trigger re-renders when their values
    //  change, any update to a ref's current value won't automatically
    //  notify consumers of the context that a change has occurred.

    // For now, we'll use that as a workaround but we can revisit this.

    const { onDragMove, onDragEnd } = useSnapping(props.stageRef, props.drawingLayerRef)

    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedId, setSelectedId] = React.useState(null);
    const [selectedElement, setSelectedElement] = React.useState(null);
    const selectedItem = selectedId && props.elems.find((e) => e.id === selectedId);


    const [isTransforming, setIsTransforming] = useState(false);
    // Selection States
    const selectionRectRef = React.useRef();
    const oldPos = React.useRef(null);
    const [nodesArray, setNodes] = React.useState([]);
    const activeNodesRef = React.useRef([]);
    const selection = React.useRef({
        visible: false,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
    });

    const selectionIsVisible = () => selection.current.visible;

    const selectNode = (node) => {
        setSelectedNodes([node]); // For simplicity, selecting one node at a time
    };

    const clearSelection = () => setSelectedNodes([]);

    // Select an element by its ID
    const selectElement = (elemId, timer = 50, konvaNode) => {

        if (konvaNode) {
            setSelectedId(elemId);
            setSelectedElement(konvaNode);
            if (props.trRef.current) {
                props.trRef.current.nodes([konvaNode]);
            }
            return;
        } else {
            if (!props.stageRef?.current) return;

            setTimeout(() => {
                // Use the stageRef to find the node by ID
                const konvaElement = props.stageRef.current.findOne(`#${elemId}`);
                setSelectedId(elemId);
                setSelectedElement(konvaElement);

                if (props.trRef.current) {
                    props.trRef.current.nodes([konvaElement]);
                }
            }, timer); // Timeout of 0 to push to the end of the event loop, after render.
        }
    };

    const deselectElement = () => {
        setSelectedId(null);
        setSelectedElement(null);
        // Clear the transformer nodes
        if (props.trRef.current) {
            props.trRef.current.nodes([]);
        }
    };

    const handleSelectionStart = (event) => {

        const isElement = event.target.findAncestor(".elements-container");
        const isTransformer = event.target.findAncestor("Transformer");
        if (isElement || isTransformer) {
            return;
        }

        const pos = event.target.getStage().getPointerPosition();
        selection.current.visible = true;
        selection.current.x1 = pos.x;
        selection.current.y1 = pos.y;
        selection.current.x2 = pos.x;
        selection.current.y2 = pos.y;

        // Create a new selection rectangle
        if (selectionRectRef && selectionRectRef.current) {
            selectionRectRef.current.setAttrs({
                visible: selection.current.visible,
                x: Math.min(selection.current.x1, selection.current.x2),
                y: Math.min(selection.current.y1, selection.current.y2),
                width: Math.abs(selection.current.x1 - selection.current.x2),
                height: Math.abs(selection.current.y1 - selection.current.y2),
                fill: "rgba(0, 161, 255, 0.3)"
            });
        }
    };

    const handleSelectionMouseMove = (event) => {
        const pos = event.target.getStage().getPointerPosition();
        selection.current.x2 = pos.x;
        selection.current.y2 = pos.y;

        // Update the selection rectangle
        if (selectionRectRef && selectionRectRef.current) {
            selectionRectRef.current.setAttrs({
                visible: selection.current.visible,
                x: Math.min(selection.current.x1, selection.current.x2),
                y: Math.min(selection.current.y1, selection.current.y2),
                width: Math.abs(selection.current.x1 - selection.current.x2),
                height: Math.abs(selection.current.y1 - selection.current.y2),
                fill: "rgba(0, 161, 255, 0.3)"
            });
        }
    };

    const handleSelectionMouseUp = (event, trRef) => {
        console.log("Selection mouse up");
        console.log("e.target:", event.target);
        console.log("trRef:", trRef);

        // if single element is clicked and it is named 'element', select it
        if (event.target.hasName('element') && event.target.type !== 'pattern') {
            selectElement(event.target.id());
        }

        // Finalize selection, trigger transformations, etc.
        setTimeout(() => {
            selection.current.visible = false;
            updateSelectionRect(selection, selectionRectRef);
        }, 0);

        const elements = props.drawingLayerRef.current.find(".element");
        const selBox = selectionRectRef.current.getClientRect();

        let selectedNodes = elements.filter((elementNode) =>
            Konva.Util.haveIntersection(selBox, elementNode.getClientRect())
        );

        let selectedElements = selectedNodes.map((node) => {
            return props.elems.find((elem) => elem.id === node.attrs.id);
        }).filter((elem) => elem !== undefined);

        // Check if all selected elements are part of the same pattern
        let selectedPatternElement = null;
        if (selectedElements.length > 0) {
            const firstElement = selectedElements[0];
            const firstPatternElement = firstElement.type === 'pattern' ? firstElement : props.elems.find((elem) => elem.type === 'pattern' && elem.elems && elem.elems.some((child) => child.id === firstElement.id));

            if (firstPatternElement) {
                const allElementsInSamePattern = selectedElements.every((element) => {
                    return element.type === 'pattern' && element.id === firstPatternElement.id || (firstPatternElement.elems && firstPatternElement.elems.some((child) => child.id === element.id));
                });

                if (allElementsInSamePattern) {
                    selectedPatternElement = firstPatternElement;
                }
            }
        }

        if (selectedPatternElement) {
            // If all selected elements are part of the same pattern, select the pattern
            setSelectedId(selectedPatternElement.id);
            setSelectedElement(selectedPatternElement);
            let patternGroup = props.drawingLayerRef.current.findOne(`#${selectedPatternElement.id}`);
            trRef.current.nodes([patternGroup]);
        } else if (selectedElements.length > 0) {
            // Select non-pattern elements
            setSelectedId(null);
            setSelectedElement(null);
            trRef.current.nodes(selectedNodes);
        } else {
            // No elements selected
            setSelectedId(null);
            setSelectedElement(null);
            trRef.current.nodes([]);
        }
    };

    return (
        <SelectionContext.Provider value={{
            selectedNodes,
            isTransforming,
            selectNode,
            clearSelection,
            selectionRectRef,
            oldPos,
            nodesArray,
            setNodes,
            activeNodesRef,
            selection,
            handleSelectionStart,
            handleSelectionMouseMove,
            handleSelectionMouseUp,
            selectionIsVisible,
            onDragEnd,
            onDragMove,
            selectedId,
            setSelectedId,
            selectedElement,
            setSelectedElement,
            selectElement,
            deselectElement,
            selectedItem,
            ...props
        }}>
            {children}
        </SelectionContext.Provider>
    );
};

export const KonvaSelectionProvider = ({ children }) => {
    const selectionContextValue = useSelectionContext(); // Get values from the original context

    return (
        <SelectionProvider value={selectionContextValue}>
            {children}
        </SelectionProvider>
    );
};