import {buildColorMap, updateSVGColors, extractColorsFromSVG} from "./propertiesUtils";
import React from "react";
import {Swatch} from "./SwatchControls";
import colorsIcon from "../../../../assets/images/icon/color.svg";
import {Dropdown} from "react-bootstrap";
import {ChromePicker, SliderPicker} from "react-color";
import {base64encode} from "../lib/utils";
import {useSelectionContext} from "../../contexts/SelectionContext";


export const ColorControls = ({
                                  isMobileView,
                                  onButtonClick,
                                  mobileIconStyles,
                                  //selectedId,
                                  //selectedItem,
                                  //selectedElement,
                                  props
                              }) => {
    const {
        selectedId,
        selectedItem,
        selectedElement
    } = useSelectionContext();

    if (isMobileView) {
        return (
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={onButtonClick}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Colors"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${colorsIcon})`
                    }}
                />
                <div>Colors</div>
            </button>
        )
    } else {
        return (
            <Dropdown>
                <Dropdown.Toggle>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <div
                            className={isMobileView ? "mobile_context_button_icon" : ""}
                            data-icon="Colors"
                            style={{
                                ...mobileIconStyles.desktopIcon,
                                backgroundImage: `url(${colorsIcon})`,
                            }}
                        />
                        Colors
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        // If it's not an SVG
                        (selectedElement?.format !== 'svg') ?
                            // if it's an image
                            (selectedElement?.type === 'image') ?
                                // Do image stuff or nothing
                                null
                                :
                                // Do shape stuff
                                <FillColorMagic
                                    elems={props.elems}
                                    onElementsChange={props.onElementsChange}
                                    selectedElement={selectedElement}
                                    selectedId={selectedId}
                                    selectedItem={selectedItem}
                                />
                            :
                            //If it is an SVG
                            <FillColorMagic
                                buildColorMap={buildColorMap}
                                updateSVGColors={updateSVGColors}
                                elems={props.elems}
                                onElementsChange={props.onElementsChange}
                                selectedElement={selectedElement}
                                selectedId={selectedId}
                                selectedItem={selectedItem}
                            />
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }

}

const ShapeColorMagic = (props) => {
    const [shapeColors, setShapeColors] = React.useState([]);
    const {
        selectedId,
        selectedItem,
        selectedElement
    } = useSelectionContext();

    return (
        <Swatch
            fillValue={selectedItem.fill}
            onElementsChange={onElementsChange}
            elems={props.elems}
            setShapeColors={setShapeColors}
            //buildColorMap={props.buildColorMap}
            selectedId={selectedId}
            selectedElement={selectedElement}
            selectedItem={selectedItem}
        />
    )
}

const FillColorMagic = (props) => {
    const {
        selectedId,
        selectedItem,
        selectedElement
    } = useSelectionContext();

    const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
    const [fillColor, setFillColor] = React.useState();
    const [selectedCssClass, setSelectedCssClass] = React.useState(null);
    const [svgColors, setSvgColors] = React.useState([]);
    const [extractedColors, setExtractedColors] = React.useState([]);
    const [currentColors, setCurrentColors] = React.useState({});

    React.useEffect(() => {
        if (selectedItem && selectedItem.format === 'svg') {
            const uniqueColors = extractColorsFromSVG(selectedItem?.svgXML);
            setExtractedColors(uniqueColors);

            // Initialize currentColors
            let initialColorMapping = {};
            uniqueColors.forEach(color => {
                initialColorMapping[color] = color;
            });
            setCurrentColors(initialColorMapping);
        }
    }, [props.selectedElement]);

    React.useEffect(() => {
        // If the selected item is a path, extract the fill color
        if (selectedItem && selectedItem.type === 'path') {
            // Extract the fill color from the selected item
            setExtractedColors([selectedItem?.fill]);
        }
    }, [selectedElement]);


    const extractUniqueColorsFromSVG = (svgXml) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgXml, "text/xml");

        const elementsWithFill = xmlDoc.querySelectorAll("[fill]");
        const uniqueColorsSet = new Set();

        elementsWithFill.forEach(element => {
            const color = element.getAttribute("fill");
            if (color && color !== "none") {
                uniqueColorsSet.add(color);
            }
        });

        const styleTags = xmlDoc.getElementsByTagName("style");
        let allCssRules = "";

        for (let i = 0; i < styleTags.length; i++) {
            allCssRules += styleTags[i].textContent;
        }

        const colorRegex = /fill:\s*(#[0-9a-fA-F]{3,6}|rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)|rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*[0-1]?\.?\d*\s*\))/g;
        const matchedColors = allCssRules.match(colorRegex) || [];

        matchedColors.forEach(match => {
            const color = match.replace("fill:", "").trim();
            uniqueColorsSet.add(color);
        });

        return [...uniqueColorsSet];
    };



    function handleChangeFillColor(color) {

        const index = props.elems.findIndex((arrItem) => arrItem.id === selectedId);
        let elems_copy = [...props.elems]; // Use the spread operator for copying arrays

        if (selectedItem.format === 'svg') {
            let newColor = color.hex.toLowerCase(); // Normalize the new color to lowercase
            let colorMap = {};

            // Use the current color as the key in colorMap
            const currentColor = (currentColors[selectedCssClass] || selectedCssClass).toLowerCase(); // Normalize current color to lowercase
            colorMap[currentColor] = newColor;

            // Re-parse the SVG and apply color changes
            let updatedSVG = updateSVGColors(colorMap, selectedItem.svgXML);

            // Ensure you are using a proper base64 encoding function
            const newData = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(updatedSVG)));
            const updatedItem = {...selectedItem, svgXML: updatedSVG, data: newData};
            elems_copy[index] = updatedItem; // Update the specific item

            props.onElementsChange(elems_copy);
            setExtractedColors(extractColorsFromSVG(updatedSVG));

            // Update the current color mapping
            let updatedCurrentColors = {...currentColors, [selectedCssClass]: newColor};
            setCurrentColors(updatedCurrentColors);
            setSelectedCssClass(newColor); // Update selectedCssClass to the new color
        }
        else if (selectedItem.type === 'path') {
            // Update the fill color of the selected item
            elems_copy[index].fill = color.hex;
            props.onElementsChange(elems_copy);
            setFillColor(color.hex); // You might want to normalize this as well
        }



        setFillColor(color.hex); // You might want to normalize this as well
    }







    function handleFillColorClick(cssClass) {
        setSelectedCssClass(cssClass); // which swatch was clicked.
        //setDisplayColorPicker(!displayColorPicker);
        setDisplayColorPicker(true);
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {extractedColors.map((color, index) => {
                    return (
                        <Swatch key={index}
                                cssClass={color} // Adjust based on Swatch component expectations
                                isSelected={selectedCssClass === color}
                                fillValue={color}
                                onElementsChange={props.onElementsChange}
                                elems={props.elems}
                                setSvgColors={setSvgColors}
                                selectedId={selectedId}
                                selectedElement={selectedElement}
                                selectedItem={selectedItem}
                                isMobileView={props.isMobileView}
                                displayColorPicker={displayColorPicker}
                                setDisplayColorPicker={setDisplayColorPicker}
                                fillColor={fillColor}
                                setFillColor={setFillColor}
                                handleFillColorClick={() => handleFillColorClick(color)}
                        />
                    );
                })}
            </div>
            {displayColorPicker && (
                <div>
                    <div style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}} onClick={() => setDisplayColorPicker(false)}/>
                    {props.isMobileView ?
                        <div style={{position: 'fixed', bottom: '10px', left: '5%', width: '90%'}}>
                            <SliderPicker
                                color={fillColor}
                                onChange={handleChangeFillColor}
                            />
                        </div>
                        :
                        <ChromePicker
                            color={fillColor}
                            onChange={handleChangeFillColor}
                            disableAlpha={true}
                            style={{position: 'absolute', zIndex: '2'}}
                        />
                    }
                </div>
            )}
        </div>
    )



}


export const ColorControlsContent = ({
                                         buildColorMap,
                                         updateSVGColors,
                                         elems,
                                         setElems,
                                         selectedId,
                                         selectedItem,
                                         onElementsChange,
                                         selectedElement,
                                         mobileIconStyles,
                                         isMobileView,
                                         placementRef,
                                     }) => (
    <div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>

            {/* Render SVG Colors */}
            {selectedItem?.svgFills && typeof selectedItem?.svgFills === 'object' &&
                <FillColorMagic
                    buildColorMap={buildColorMap}
                    updateSVGColors={updateSVGColors}
                    elems={elems}
                    onElementsChange={onElementsChange}
                    selectedElement={selectedElement}
                    selectedId={selectedId}
                    selectedItem={selectedItem}
                    isMobileView={true}
                />
            }

            {/* Render Shape Colors */}
            {selectedItem?.type !== 'image' && selectedItem?.type !== 'text' && typeof selectedItem?.svgFills !== 'object' &&
                <ShapeColorMagic
                    elems={elems}
                    onElementsChange={onElementsChange}
                    selectedElement={selectedElement}
                    selectedId={selectedId}
                    selectedItem={selectedItem}
                />
            }

            {/* Render Text Colors */}
            {selectedItem?.type === 'text' &&
                <FillColorMagic
                    elems={elems}
                    onElementsChange={onElementsChange}
                    selectedElement={selectedElement}
                    selectedId={selectedId}
                    selectedItem={selectedItem}
                />
            }

        </div>
    </div>
);


