import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import formattingIcon from "../../../../assets/images/icon/formatting.svg";
import Konva from "konva";

export const useFontFormatting = (selectedItem, elems, onElementsChange, selectedId, selectedElement) => {
    const [fontWeight, setFontWeight] = useState(selectedItem.fontWeight || 'normal');
    const [fontStyle, setFontStyle] = useState(selectedItem.fontStyle || 'normal');
    const [fontSize, setFontSize] = useState(selectedItem.fontSize || 153);
    const [curvature, setCurvature] = useState(selectedItem.curvature || 0);

    const [letterSpacing, setLetterSpacing] = useState(selectedItem.letterSpacing || 0);
    const [lineHeight, setLineHeight] = useState(selectedItem.lineHeight || 1);
    const [textDecoration, setTextDecoration] = useState(selectedItem.textDecoration || 'none');
    const [textTransform, setTextTransform] = useState(selectedItem.textTransform || 'none');
    const [isInverted, setIsInverted] = useState(false); // New state for inversion toggle


    const handleFontWeightChange = (e, selectedFontWeight) => {
        e.stopPropagation();
        const updatedFontWeight = selectedFontWeight;
        const element = {
            ...selectedItem,
            fontWeight: updatedFontWeight
        };

        const index = elems.findIndex((e) => e.id === selectedId);
        elems[index] = element;
        onElementsChange(elems.slice());
    }

    const handleFontStyleChange = (e, selectedFontStyle) => {
        e.stopPropagation();
        const updatedFontStyle = selectedFontStyle;
        const element = {
            ...selectedItem,
            fontStyle: updatedFontStyle
        };

        const index = elems.findIndex((e) => e.id === selectedId);
        elems[index] = element;
        onElementsChange(elems.slice());
    }

    // Handler for toggling the inversion state
    const handleInvertToggle = () => {
        // This will toggle the isInverted state
        setIsInverted(!isInverted);

        // Calculate the radius based on the current curvature, but flip the sign if we're inverting
        const radius = curvatureToRadius(Math.abs(curvature), !isInverted);

        // Measure the width of the text to determine the arc length
        const arcLength = measureTextWidth(selectedItem.text, selectedItem.fontSize, selectedItem.fontFamily);

        // Generate the new path data with the updated curvature direction
        const pathData = generateArcPath(arcLength, radius, !isInverted);

        // Set the path data on the selected Konva element
        selectedItem.path = pathData;
        selectedElement.data(pathData);

        // Trigger the change in the parent component's state
        onElementsChange([...elems]);

        // Redraw the layer to update the canvas
        selectedElement.getLayer().batchDraw();
    };



    const handleFontSizeChange = (e, selectedFontSize) => {
        e.stopPropagation();
        const updatedFontSize = selectedFontSize;
        const element = {
            ...selectedItem,
            fontSize: updatedFontSize
        };

        const index = elems.findIndex((e) => e.id === selectedId);
        elems[index] = element;
        onElementsChange(elems.slice());
    }

    const measureTextWidth = (text, fontSize, fontFamily) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = `${fontSize}px ${fontFamily}`;
        return context.measureText(text).width;
    };


    // Handler for changes in the curvature slider
    // Handler for changes in the curvature slider
    const handleCurvatureChange = (e) => {
        const newCurvature = parseFloat(e.target.value);
        setCurvature(newCurvature);

        const radius = curvatureToRadius(Math.abs(newCurvature));
        const arcLength = measureTextWidth(selectedItem.text, selectedItem.fontSize, selectedItem.fontFamily);

        let pathData;
        if (newCurvature === 0) {
            // If curvature is 0, set path to a straight line
            pathData = `M 0 0 L ${arcLength} 0`;
        } else {
            // Generate curved path
            pathData = generateArcPath(arcLength, radius, newCurvature < 0);
        }

        // Update the selectedItem and copy of elems array
        const updatedElement = { ...selectedItem, curvature: newCurvature, path: pathData, data: pathData };
        const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
        let elemsCopy = [...elems];
        elemsCopy[index] = updatedElement;

        // Update Konva Path and trigger state change
        selectedElement.data(pathData);
        onElementsChange(elemsCopy);
        selectedElement.getLayer().batchDraw();
    };




// Function to map curvature to radius if needed
    const curvatureToRadius = (curvature) => {
        // Assuming curvature is a proportion of the width of the text
        const width = measureTextWidth(selectedItem.text, selectedItem.fontSize, selectedItem.fontFamily);
        return width / curvature;
    };

    const generateArcPath = (arcLength, radius, isConcave) => {
        const halfLength = arcLength / 2;
        const height = radius - Math.sqrt(radius * radius - halfLength * halfLength);

        const centerXOffset = 0;
        const startX = centerXOffset - halfLength;
        const startY = isConcave ? height : -height;
        const endX = centerXOffset + halfLength;
        const endY = startY;

        const sweepFlag = isConcave ? '0' : '1';
        const largeArcFlag = '0';

        const pathData = `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}`;
        return pathData;
    };



    const handleLetterSpacingChange = (e, selectedLetterSpacing) => {
        e.stopPropagation();
        const updatedLetterSpacing = selectedLetterSpacing;
        const element = {
            ...selectedItem,
            letterSpacing: updatedLetterSpacing
        };

        const index = elems.findIndex((e) => e.id === selectedId);
        elems[index] = element;
        onElementsChange(elems.slice());
    }

    return {
        fontSize,
        curvature,
        letterSpacing,
        handleFontSizeChange,
        handleCurvatureChange,
        handleLetterSpacingChange,
        handleInvertToggle,
        isInverted
    };
};


export const FontFormattingControls = ({ isMobileView,
                                           selectedItem,
                                           elems,
                                           setElems,
                                           onElementsChange,
                                           selectedId,
                                           selectedElement,
                                           onButtonClick,
                                           mobileIconStyles,
                                           ...props
                                       }) => {

    const {
        fontSize,
        curvature,
        letterSpacing,
        handleFontSizeChange,
        handleCurvatureChange,
        handleInvertToggle,
        handleLetterSpacingChange,
        isInverted
    } = useFontFormatting(selectedItem, elems, onElementsChange, selectedId, selectedElement);
    // Add a new function to handle the inversion toggle


    if (isMobileView) {
        return (
            <button
                style={mobileIconStyles.mobile}
                className="mobile_context_button"
                onClick={onButtonClick}
            >
                <div
                    className="mobile_context_button_icon"
                    data-icon="Fonts"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${formattingIcon})`
                    }}
                />
                <div>Curve</div>
            </button>
        );
    } else {
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <div
                                className={isMobileView ? "mobile_context_button_icon" : ""}
                                data-icon="Fonts"
                                style={{
                                    ...mobileIconStyles.desktopIcon,
                                    backgroundImage: `url(${formattingIcon})`,
                                }}
                            />
                            Curve
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <PropertyRow
                            icon="Curvature"
                            label="Curvature"
                            min={-2}
                            max={2}
                            step={0.05}
                            value={curvature}
                            onChange={handleCurvatureChange}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }
}



const PropertyRow = ({ icon, label, min, max, step, value, onChange }) => {
    return (
        <div className="property-row">
            <div className="property-icon">
                <span aria-hidden="true" className="icon" data-icon={icon}></span>
            </div>
            <div className="property-label" id={`property-${label}`}>{label}</div>
            <div className="property-inputs">
                <div className="slider-input">
                    <input
                        aria-labelledby={`property-${label}`}
                        className="range-slider"
                        type="range"
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        onChange={onChange}
                    />
                </div>
                <div className="numeric-input">
                    <input
                        aria-labelledby={`property-${label}`}
                        type="number"
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        onChange={onChange}
                    />
                    <div className="step-buttons">
                        <button className="step-btn" type="button" onClick={() => onChange(value - step)}>
                            <span aria-hidden="true" className="icon" data-icon="CaretDown"></span>
                        </button>
                        <button className="step-btn" type="button" onClick={() => onChange(value + step)}>
                            <span aria-hidden="true" className="icon" data-icon="CaretDown"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export const FontFormattingControlsContent = ({
                                                  selectedItem,
                                                  onElementsChange,
                                                  elems,
                                                  selectedId,
                                                  selectedElement,
                                              }) => {
    const {
        fontSize,
        curvature,
        letterSpacing,
        handleFontSizeChange,
        handleCurvatureChange,
        handleInvertToggle,
        handleLetterSpacingChange,
        isInverted
    } = useFontFormatting(selectedItem, elems, onElementsChange, selectedId, selectedElement);

    return (
            <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', gap: '15px', maxHeight: '200px' }}>
                <div className="mobile-slider-control">
                    <label>Curvature: {curvature.toFixed(2)}</label>
                    <input
                        style={{ width: '100%', padding: '5px 0', appearance: 'none' }}
                        type="range"
                        min={-2}
                        max={2}
                        step={0.05}
                        value={curvature}
                        onChange={handleCurvatureChange}
                    />
                </div>
            </div>
    );
}
