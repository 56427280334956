import React from 'react';
import { Path } from 'react-konva';

// This is the Text Element
export const PathShape = ({
                              onSelect,
                              isSelected,
                              onChange,
                              element,
                              trRef,
                              draggable,
                              onMouseOver,
                              onMouseOut,
                              isElementTouchedRef,
                              addToActiveNodes,
                              removeFromActiveNodes
                          }) => {

    const shapeRef = React.useRef(null);

    React.useEffect(() => {
        if (isSelected && shapeRef.current) {
            if (trRef.current) {
                addToActiveNodes(shapeRef.current);
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        } else if (!isSelected && shapeRef.current) {
           if (removeFromActiveNodes) removeFromActiveNodes(shapeRef.current);
        }
    }, [isSelected]);

    React.useEffect(() => {
        return () => {
            if (shapeRef.current) {
                removeFromActiveNodes(shapeRef.current);
            }
        }
    }, []);

    return (
        <Path
            data={element.data} // SVG path data
            originalData={element.originalData}
            id={element.id.toString()}
            name="element"
            x={element.x}
            y={element.y}
            type={element.type}
            textValue={element.textValue}
            distortionType={element.distortionType}
            bendValue={element.bendValue}
            horizontalDistort={element.horizontalDistort}
            verticalDistort={element.verticalDistort}
            fontFamily={element.fontFamily}
            fontSize={element.fontSize}
            fill={element.fill || 'black'}
            stroke={element.stroke || 'black'}
            strokeWidth={element.strokeWidth || 0}
            hitStrokeWidth={0}
            draggable={draggable}
            onClick={onSelect}
            onTap={onSelect}
            ref={shapeRef}
            scaleX={element.scaleX}
            scaleY={element.scaleY}
            rotation={element.rotation}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onDragEnd={(e) => {
                // Handle drag end
                const node = shapeRef.current;
                onChange({
                    ...element,
                    x: node.x(),
                    y: node.y(),
                    rotation: node.rotation(),
                    scaleX: node.scaleX(),
                    scaleY: node.scaleY(),
                });
            }}
            onTransformEnd={(e) => {
                // Handle transform end
                const node = shapeRef.current;
                // You might need to adjust this part to fit your app's state management
                onChange({
                    ...element,
                    x: node.x(),
                    y: node.y(),
                    rotation: node.rotation(),
                    scaleX: node.scaleX(),
                    scaleY: node.scaleY(),
                });
            }}
        />
    );
};

export const generatePathShapeThumbnail = async (element) => {
    const thumbnailWidth = 80;
    const thumbnailHeight = 80;

    return new Promise((resolve) => {
        // Create a canvas for drawing
        const canvas = document.createElement('canvas');
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;
        const context = canvas.getContext('2d');

        // Clear the canvas and set up styles
        context.clearRect(0, 0, thumbnailWidth, thumbnailHeight);
        context.fillStyle = 'black';
        context.font = 'bold 40px Arial'; // Adjust the font and size as necessary
        context.textAlign = 'center';
        context.textBaseline = 'middle';

        // Draw the "T" symbol centered in the canvas
        context.fillText('T', thumbnailWidth / 2, thumbnailHeight / 2);

        // Draw the `textValue` beneath it
        context.font = '12px Arial';
        context.fillText(element.textValue || '', thumbnailWidth / 2, thumbnailHeight - 10);

        resolve(canvas.toDataURL()); // Return the data URL as the thumbnail
    });
};


export default PathShape;
