import React from "react";
import setAxiosHeaders from "../lib/AxiosHeaders";
import axios from "axios";
import AssetItem     from "./AssetItem";

const AssetList = ({ type, loadElement, isListVisible }) => {
    if (!isListVisible) return null;

    const [assetContents, setAssetContents] = React.useState([]);
    const [loading, setLoading] = React.useState(true); // add this



    const apiURL = {
        Photos: '/api/v1/elements/search/raster',
        Icons: '/api/v1/elements/search/svg',
        Templates: '/api/v1/designs',
        Products: '/api/v1/product_types',
    };


    React.useEffect(() => {
        setLoading(true); // set loading to true here

        setAxiosHeaders();
        if (apiURL[type] === undefined) {
            setAssetContents([]);
        }
        const fetchData = async () => {
            const result = await axios(apiURL[type]);
            if (result.data.length > 0) {
                setAssetContents(result.data);
            } else {
                setAssetContents([]);
            }
            setLoading(false); // set loading to false here

        };
        fetchData();
        console.log(assetContents);
    }, [type]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <ul className='sidebar-menu list-unstyled collapse' id={`${type}Dropdown`} style={{ display: 'block' }}>
            {assetContents.map((asset, index) => (
                <AssetItem key={index} asset={asset} onSelect={loadElement} type={type} />
            ))}
        </ul>
    );

};

export default AssetList;