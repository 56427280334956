/* eslint-disable react/prop-types */
import setAxiosHeaders from '../AxiosHeaders';
import axios from 'axios';
import { useMutation } from 'react-query';
import React from 'react';

const axiosAddProduct = (formData) => {
    // Log the contents of formData
    for (let pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
    }

    setAxiosHeaders();
    return axios({
        method: 'post',
        url: '/api/v1/products',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const useSaveProductData = (callbackAfterSave) => {
    return useMutation(axiosAddProduct, {
        onSuccess: (data) => {
            console.log('Response data:', data);
            let product_path = data?.data.product_path;
            if (product_path) {
                if (callbackAfterSave) {
                    sessionStorage.setItem('redirectToProductAfterSave', product_path);
                    callbackAfterSave();
                } else {
                    // Wait for Promise to resolve before redirecting
                    setTimeout(() => {
                        window.location.href = product_path;
                    }, 100);
                }
            } else {
                console.error('Product path is undefined:', data);
                alert('Error saving product. Please try again.');
            }
        },
    });
};

export const CreateNewProduct = (props) => {
    const [previewImageBlobs, setPreviewImageBlobs] = React.useState([]);
    const { mutate: addProduct } = useSaveProductData(showAuthModal);

    const saveProduct = (e, callbackAfterSave) => {
        e.preventDefault();

        let formDataPreviewImages = new FormData();
        formDataPreviewImages.append('product[title]', props.title);
        formDataPreviewImages.append(
            'product[product_type_id]',
            props.productTypeId
        );
        formDataPreviewImages.append(
            'product[product_variant_id]',
            props.selectedProductVariantId
        );
        formDataPreviewImages.append(
            'product[external_variant_id]',
            props.externalVariantId
        );
        formDataPreviewImages.append(
            'product[external_product_id]',
            props.externalProductId
        );
        formDataPreviewImages.append(
            'product[state]',
            JSON.stringify(props.elems)
        );

        addProduct(formDataPreviewImages, {
            onSuccess: callbackAfterSave
        });
    };

    const showAuthModal = () => {
        $('#loginModal').modal('show');
    }

    return (
        <div>
            <button className="btn btn-primary" onClick={e => saveProduct(e)}>
                Save Product
            </button>

        </div>
    );
};

