import React, { useState } from 'react';
import { SimplePreviewer } from "../SimplePreviewer/SimplePreviewer";

export const PreviewerSliderPanel = ({ isMobileView, isOpen, onClose, ...props }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleClose = () => {
        if (!isDragging) {
            onClose();
        }
    };

    return (
        <div className={`slide-up ${isOpen ? 'open' : ''}`}>
            <div className="top-row-close" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={handleClose}>
                Tap here to close
            </div>
            <SimplePreviewer
                product_id={props.product_id}
                previewStageRef={props.previewStageRef}
                previewPaneRef={props.previewPaneRef}
                elems={props.elems}
                templates={props.templates}
                selectedPreviewPaneTemplateId={props.selectedPreviewPaneTemplateId}
                setSelectedPreviewPaneTemplateId={props.setSelectedPreviewPaneTemplateId}
                setProductPhotoURLs={props.setProductPhotoURLs}
                isMobileView={isMobileView}
                onClose={onClose}
            />
        </div>
    );
}
